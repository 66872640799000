import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbsNavigationKeys, BreadcrumbsNavigationVariation1Keys } from 'constants/breadcrumbs-navigation';

import { ErrorBlock } from 'components/paywall/error-block';
import { PrimerIOPaywall } from 'components/paywall/PrimerIO';
import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Cart } from './cart';
import { OrderDetails } from './order-details';
import { BreadcrumbsNavigation } from './breadcrumbs-navigation';
import { AccountForm, isValidAccountForm } from './account-form';
import { ShippingAddressForm, isValidShippingAddressForm, getShippingAddressDetails } from './shipping-address-form';
import { VerifiedForm } from './verified-form';

import { usePrimerIOPayment } from './use-primerio-payment.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { getPaymentGeneralErrorKey } from 'helpers/payment';

import { UserExistsMessage } from 'pages/checkout/user-exists-message';
import { PrimerLoader } from 'pages/checkout/primer-loader';
import { BreadcrumbsNavigationVariation1 } from 'pages/checkout/breadcrumbs-navigation-variation-1';

import { useStyles } from './styles';

export const Checkout = () => {
    const { t } = useTranslation();

    const {
        pageWrapper,
        checkoutContentWrapper,
        cartWrapper,
        shippingAddressFormDetailsWrapper,
        paymentWrapper,
        paymentWrapperVisible,
        title,
        primerLoadingWrapper,
        transparentContainer,
    } = useStyles();

    const isDesktop = useDesktopMediaWatcher();

    const accountFormValid = isValidAccountForm();
    const shippingFormValid = isValidShippingAddressForm();

    const getShippingAddress = () => {
        const { streetAddress, city, state, zipCode } = getShippingAddressDetails();

        return `${streetAddress}, ${city}, ${state}, ${zipCode}`;
    };

    const mw_progress_bar_on_checkout_screen = localStorage.getItem(ExperimentsKeys.mw_progress_bar_on_checkout_screen) === '1';

    const [accountFormVerified, setAccountFormVerified] = useState(accountFormValid);
    const [shippingAddressFormVerified, setShippingAddressFormVerified] = useState(shippingFormValid);
    const [activeSection, setActiveSection] = useState(accountFormValid && shippingFormValid ? BreadcrumbsNavigationKeys.checkout : BreadcrumbsNavigationKeys.account);

    const calculateInitialSections = () => {
        let initialActiveSection: string;
        let initialCompletedSections: string[];

        if (accountFormValid && shippingFormValid) {
            initialActiveSection = BreadcrumbsNavigationVariation1Keys.payment;
            initialCompletedSections = [BreadcrumbsNavigationVariation1Keys.healthPlan, BreadcrumbsNavigationVariation1Keys.account, BreadcrumbsNavigationVariation1Keys.shipping];
        } else if (accountFormValid && !shippingFormValid) {
            initialActiveSection = BreadcrumbsNavigationVariation1Keys.shipping;
            initialCompletedSections = [BreadcrumbsNavigationVariation1Keys.healthPlan, BreadcrumbsNavigationVariation1Keys.account];
        } else {
            initialActiveSection = BreadcrumbsNavigationVariation1Keys.account;
            initialCompletedSections = [BreadcrumbsNavigationVariation1Keys.healthPlan];
        }

        return { initialActiveSection, initialCompletedSections };
    };

    const { initialActiveSection, initialCompletedSections } = calculateInitialSections();
    const [activeSectionExp, setActiveSectionExp] = useState<string>(initialActiveSection);
    const [completedSections, setCompletedSections] = useState<string[]>(initialCompletedSections);

    const [address, setAddress] = useState(shippingFormValid ? getShippingAddress() : '');

    const {
        plan,
        email,
        error,
        createSubscriptionLoadingRequest,
        onCheckoutFail,
        onBeforePaymentCreate,
        onCheckoutComplete,
    } = usePrimerIOPayment();

    const [showUserExistsMessagePrimer, setShowUserExistsMessagePrimer] = useState<boolean>(false);

    const handleAccountFormSubmit = () => {
        setAccountFormVerified(true);
        setActiveSection(BreadcrumbsNavigationKeys.checkout);
        setCompletedSections(prevState => [...prevState, BreadcrumbsNavigationVariation1Keys.account]);
        setActiveSectionExp((shippingFormValid && shippingAddressFormVerified)
            ?
            BreadcrumbsNavigationVariation1Keys.payment
            :
            BreadcrumbsNavigationVariation1Keys.shipping
        );
    };

    const handleAccountFormEdit = () => {
        setAccountFormVerified(false);
        setActiveSection(BreadcrumbsNavigationKeys.account);
        setActiveSectionExp(BreadcrumbsNavigationVariation1Keys.account);
    };

    const handleShippingAddressFormSubmit = (address: string) => {
        setAddress(address);
        setShippingAddressFormVerified(true);
        setActiveSection(BreadcrumbsNavigationVariation1Keys.payment);
        setCompletedSections(prevState => [...prevState, BreadcrumbsNavigationVariation1Keys.shipping]);
        setActiveSectionExp(BreadcrumbsNavigationVariation1Keys.payment);
    };

    const handleShippingAddressFormEdit = () => {
        setShippingAddressFormVerified(false);
        setActiveSection(BreadcrumbsNavigationVariation1Keys.shipping);
        setActiveSectionExp(BreadcrumbsNavigationVariation1Keys.shipping);
    };

    const translationPaywall = {
        creditCard: {
            generalError: t(getPaymentGeneralErrorKey()),
            creditCardForm: {
                input: {
                    namePlaceholder: t('checkout-credit-card-name-placeholder'),
                    cardNumberPlaceholder: t('checkout-credit-card-card-number-placeholder'),
                    cardExpiryPlaceholder: t('checkout-credit-card-card-expiry-placeholder'),
                    cardCvvPlaceholder: t('checkout-credit-card-card-cvv-placeholder'),
                },
                submitButtonText: t('checkout-credit-card-submit-button-text'),
            },
        },
    };

    const [primerLoadingRequest, setPrimerLoadingRequest] = useState(false);
    const [showUserExistsErrorMessageExp, setShowUserExistsErrorMessageExp] = useState<boolean>(false);

    return (
        <>
            {!isDesktop && <Cart isDesktop={isDesktop} />}

            <div className={pageWrapper}>

                <div className={checkoutContentWrapper}>
                    <div>

                        {mw_progress_bar_on_checkout_screen && <BreadcrumbsNavigationVariation1 activeSection={activeSectionExp} completedSections={completedSections} />}
                        {!mw_progress_bar_on_checkout_screen && <BreadcrumbsNavigation activeSection={activeSection} />}

                        {!accountFormVerified && !showUserExistsErrorMessageExp &&
                            <AccountForm
                                handleSubmit={handleAccountFormSubmit}
                                setShowUserExistsErrorMessageExp={setShowUserExistsErrorMessageExp}
                            />
                        }
                        {accountFormVerified && !showUserExistsErrorMessageExp &&
                            <VerifiedForm title={t('checkout-account-form-verified-form-title')} text={email}
                                          handleEdit={handleAccountFormEdit} />}

                        {accountFormVerified && !showUserExistsErrorMessageExp &&
                            <>
                                {!shippingAddressFormVerified &&
                                    <ShippingAddressForm handleSubmit={handleShippingAddressFormSubmit} />}

                                {shippingAddressFormVerified &&
                                    <div className={shippingAddressFormDetailsWrapper}>
                                        <VerifiedForm title={t('checkout-shipping-address-verified-form-title')}
                                                      text={address} handleEdit={handleShippingAddressFormEdit} />
                                    </div>
                                }
                            </>
                        }

                        {error && <ErrorBlock error={error} />}

                        {!showUserExistsErrorMessageExp && <div className={`${paymentWrapper} ${accountFormVerified && shippingAddressFormVerified && paymentWrapperVisible}`}>
                            <Typography variant={TypographyVariants.h1} className={title}>
                                3. Payment method
                            </Typography>

                            {!showUserExistsMessagePrimer && accountFormVerified && shippingAddressFormVerified &&
                                <div className={`${(createSubscriptionLoadingRequest || primerLoadingRequest) && primerLoadingWrapper} 
                                                ${createSubscriptionLoadingRequest && transparentContainer}`}>

                                    {createSubscriptionLoadingRequest && <PrimerLoader />}

                                    <PrimerIOPaywall
                                        email={email}
                                        amount={plan.amount}
                                        currencyCode={plan.currencyCode}
                                        onError={onCheckoutFail}
                                        onBeforePaymentCreate={onBeforePaymentCreate}
                                        onPaymentCreationStart={setPrimerLoadingRequest}
                                        onCheckoutComplete={onCheckoutComplete}
                                        onCheckoutFail={onCheckoutFail}
                                        translation={translationPaywall.creditCard}
                                        setShowUserExistsMessagePrimer={setShowUserExistsMessagePrimer}
                                    />
                                </div>
                            }

                            {showUserExistsMessagePrimer && <UserExistsMessage />}
                        </div>}

                        {showUserExistsErrorMessageExp && <UserExistsMessage />}

                        <OrderDetails />

                    </div>
                </div>

                {isDesktop && <div className={cartWrapper}><Cart isDesktop={isDesktop} /></div>}
            </div>
        </>
    );
};
