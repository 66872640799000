import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { ChoiceButton, ChoiceType } from 'components/choice-button';

import fish_boxes from 'assets/images/webp/fish_boxes.webp';
import fish_boxes_mobile from 'assets/images/webp/fish_boxes_mobile.webp';
import logo from 'assets/images/svg/logo-black.svg';

import { useStyles } from './styles';

export const WeightLossGoal = () => {
    const { t } = useTranslation();
    const isDesktop = useDesktopMediaWatcher();
    const saveAnswers = useSaveAnswers();
    const { pageConfiguration } = useContext(BaseContext);

    const { pageWrapper, fishImage, ableLogo, wrapper } = useStyles();

    const pageKey = LocalStorageKeys[RoutePath.Goal];

    const { pageValue: weightLossGoal, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (goal: ChoiceType) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('weight-loss-goal-title'), value: goal.value },
        });

        setPageValue(goal.key);
        pageConfiguration.handleOnPageComplete(goal.key);
    };

    const goals: ChoiceType[] = [
        { key: 'lose-under-twenty-lbs', value: t('weight-loss-goal-option-lose-under-twenty-lbs') },
        { key: 'lose-under-fifty-lbs', value: t('weight-loss-goal-option-lose-under-fifty-lbs') },
        { key: 'lose-over-fifty-lbs', value: t('weight-loss-goal-option-lose-over-fifty-lbs') },
        { key: 'maintain-weight', value: t('weight-loss-goal-option-maintain-weight') },
        { key: 'not-sure', value: t('weight-loss-goal-option-not-sure') },
    ];

    return (
        <>
            <img className={fishImage} src={isDesktop ? fish_boxes : fish_boxes_mobile} alt='Fish' />
            <div className={pageWrapper}>
                <img className={ableLogo} src={logo} alt='Logo' />

                <div className={wrapper}>
                    <Typography variant={TypographyVariants.h1}>
                        <Trans i18nKey='weight-loss-goal-title' />
                    </Typography>

                    {goals.map((v: ChoiceType) => (
                        <ChoiceButton
                            key={v.key}
                            center={true}
                            selected={v.key === weightLossGoal}
                            typographyText={v.value}
                            onClick={() => handleClick(v)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
