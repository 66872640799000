import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { UserEligibilityKeys } from 'constants/user-eligibility';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

import { getMultiChoiceButtonSelectOptions, isNextButtonDisabled } from 'helpers/get-multichoice-button-select-options';

export const MedicationIntake = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicationIntake];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${LocalStorageKeys[RoutePath.MedicationIntake]}-other-userAnswer`,
        defaultValue: '',
    });

    const mw_medical_director_changes_v2 = localStorage.getItem(ExperimentsKeys.mw_medical_director_changes_v2) === '1';

    const multiChoices: MultiChoiceType[] = [
        {
            key: 'insulin',
            value: t('medication-intake-option-insulin'),
        },
        {
            key: 'glp-1-receptor-agonists',
            value: t('medication-intake-option-glp-1-receptor-agonists'),
        },
        {
            key: 'sulfonylureas',
            value: t('medication-intake-option-sulfonylureas'),
        },
        {
            key: 'other',
            value: mw_medical_director_changes_v2 ?
                t('medication-intake-option-other-medication-not-listed')
                :
                t('medication-intake-option-other'),
            userAnswer,
            setUserAnswer,
        },
        {
            key: 'none',
            value: mw_medical_director_changes_v2 ?
                t('medication-intake-option-none-other-medication')
                :
                t('medication-intake-option-none'),
        },
    ];

    const handleClick = () => {
        const selectOption = getMultiChoiceButtonSelectOptions(pageValue, multiChoices);

        let userStatus = pageValue[0] === 'none' ? UserEligibilityKeys.userStatus.approved : UserEligibilityKeys.userStatus.needsReview;

        const medicalConditionPageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicalCondition]) as string);
        if (medicalConditionPageValue?.length > 0 && medicalConditionPageValue[0] !== 'none' && userStatus === UserEligibilityKeys.userStatus.approved) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        saveAnswers({
            key: LocalStorageKeys[RoutePath.MedicationIntake],
            itemQuestion: {
                question: t('medication-intake-title'),
                value: pageValue[0] === 'none'
                    ?
                    multiChoices
                        .filter(multiChoice => multiChoice.key !== 'none')
                        .map(multiChoice => `"${multiChoice.value}" - NO`)
                    :
                    selectOption,
            },
            userStatus: { question: t('medication-user-eligibility-status-question'), value: userStatus },
        });

        pageConfiguration.handleOnPageComplete(pageValue.includes('other') ? {
            pageValue,
            userAnswer: userAnswer.trim(),
        } : { pageValue });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>
                {t('medication-intake-title')}
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                {t('medication-intake-subtitle')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    textAreaInputLabel={'medication-intake-text-area-input-label'}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick}
                            disabled={mw_medical_director_changes_v2
                                ?
                                isNextButtonDisabled(pageValue, multiChoices)
                                :
                                !pageValue.length} />
            </FloatedButton>
        </>
    );
};
