export type MedicationPlanType = {
    title: string,
    description: string,
    key: string,
    amount: number,
    subscriptionAmount: number,
    trialPeriod: number,
    subscriptionPeriod: number,
    chargePeriodUnit: string,
    currencyCode: string,
    productId: number | null,
    bundleId: number | null,
    badgeTitle: string
}

export const MEDICATION_PLANS: MedicationPlanType[] = [
    {
        title: 'Semaglutide\u00A0/\u00A0GLP\u20111',
        description: "One of the most effective medications for the weight loss, enhancing satiety while controlling appetite, through GLP-1 hormone mimicry.",
        key: 'ablerxsema_v1_44900_month_USD',
        amount: 44900,
        subscriptionAmount: 44900,
        trialPeriod: 0,
        subscriptionPeriod: 30, /* chargePeriod - numbers of days or month, answer for - How often user needs to be billed (X Days) */
        chargePeriodUnit: 'day', /* period (X Days) */
        currencyCode: 'USD',
        productId: null,
        bundleId: 1,
        badgeTitle: 'Best seller'
    },
    {
        title: 'Bella Capsules',
        description: "Affordable alternative to GLP-1 for weight loss, targeting both appetite suppression and craving reduction.",
        key: 'ablerxbella_v1_14900_month_USD',
        amount: 14900,
        subscriptionAmount: 14900,
        trialPeriod: 0,
        subscriptionPeriod: 30, /* chargePeriod - numbers of days or month, answer for - How often user needs to be billed (X Days) */
        chargePeriodUnit: 'day', /* period (X Days) */
        currencyCode: 'USD',
        productId: 1,
        bundleId: null,
        badgeTitle: 'Popular'
    }
];
