import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    radioButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        alignSelf: 'stretch',
        padding: '16px',
        borderRadius: '8px',
        border: '2px solid #E0E3EB',
        background: '#FFFFFF',
        cursor: 'pointer',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',

        '& > div': {
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '12px',

            '& > img': {
                width: '100%',
                height: '100%',
                maxHeight: '235px',
                objectFit: 'cover',
                borderRadius: '16px'
            },
        },

        '& > div > h2, h3': {
            margin: 'unset',
            textAlign: 'left'
        },

        '& > div > h2': {
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 600,
        },

        '& > div > h3': {
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    radioSelector: {
        position: 'relative',

        '& > input': {
            cursor: 'pointer',
            appearance: 'none',
            width: '24px',
            height: '24px',
            border: '2px solid #E0E3EB',
            borderRadius: '50%',
            outline: 'none',
            position: 'relative',
            margin: '0',
        },

        '& > input:checked': {
            borderColor: '#1375D6',
        },

        '& > span': {
            position: 'absolute',
            top: '46%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            backgroundColor: '#1375D6',
            display: 'none',
        },

        '& > input:checked + span': {
            display: 'block',
        },
    },
    badge: {
        display: 'flex',
        padding: '6px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '50px',
        background: '#F6F6F6',
        position: 'absolute',
        top: '12px',
        left: '12px',

        '& > h3': {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 600,
        }
    },
    priceBlock: {
        display: 'flex',
        padding: '6px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        background: '#E0E3EB',

        '& > h3': {
            fontSize: '16px',
            fontWeight: 600,
        }
    },
    selected: {
        borderColor: '#1375D6',
    },
    '@media (min-width: 768px)': {
        radioButton: {
            maxWidth: '358px',

            '& > div:last-child': {
                flexBasis: '30%',
            },
        },
    }
});
