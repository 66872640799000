import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

interface ListItemProps {
    textKeys: string[];
}

export const ListItem: FC<ListItemProps> = ({ textKeys }) => {
    const pageImages = useBackgroundImageService([
        'svg/how-sema-works/arrow.svg',
    ]);

    return (
        <div>
            <img src={pageImages[0]} alt='Arrow' />
            <div>
                {textKeys.map((key, index) => (
                    <Typography key={index} variant={TypographyVariants.p}>
                        <Trans i18nKey={key} />
                    </Typography>
                ))}
            </div>
        </div>
    );
};
