import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    '@media (min-width: 768px)': {
        contentWrapper: {
            flexDirection: 'row',
            gap: '32px',
        },
    }
});
