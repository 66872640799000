import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { SimpleTimer } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { Banner } from 'components/banner';

import { useStyles } from './styles';

interface OfferBannerWithTimerProps {
    minutes: number;
    seconds: number;
    setMinutes: (minutes: number) => void;
    setSeconds: (seconds: number) => void;
}

export const OfferBannerWithTimer: FC<OfferBannerWithTimerProps> = ({
                                                                        minutes,
                                                                        seconds,
                                                                        setMinutes,
                                                                        setSeconds,
                                                                    }): JSX.Element => {
    const { title, timer } = useStyles();

    const navigate = useNavigate();

    const endOfTimerHandler = () => {
        localStorage.clear();
        // Navigate to first page
        navigate(`..${RoutePath.Main}`);
    };

    return (
        <Banner>
            <Typography variant={TypographyVariants.h3} className={title}>
                <Trans i18nKey='medication-banner-with-timer-title' />
            </Typography>
            <div className={timer}>
                <SimpleTimer
                    minutes={minutes}
                    seconds={seconds}
                    setMinutes={setMinutes}
                    setSeconds={setSeconds}
                    endOfTimerHandler={endOfTimerHandler}
                />
            </div>
        </Banner>
    );
};
