import { useCallback, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';

import { useLoadingProgress } from 'hooks/use-loading-progress.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { ThinProgressBar } from 'components/progress-bar-thin';

import { useStyles } from './styles';

export const AnalyzeResponses = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const {
        title,
        progressBarTitle,
        bar,
        titleHighlight,
        weightLossWithAbleChartWrapper,
        weightLossWithAbleChartTitleWrapper,
        weightLossWithAbleChartTitle,
        weightLossWithAbleChartTitleValueWrapper,
        weightLossWithAbleChartTitleValue,
        partyIcon,
        weightLossWithAbleChartInfo
    } = useStyles();

    const handleClick = useCallback(() => {
        pageConfiguration.handleOnPageComplete();
    }, []);

    const { progress } = useLoadingProgress({
        onDone: handleClick,
    });

    const items = ['svg/party-icon.svg', 'svg/analyze-responses/weight-loss-with-able-chart.svg'];
    const pageImages = useBackgroundImageService(items);

    const mw_pre_paywall_on_paywall = useFeatureIsOn(ExperimentsKeys.mw_pre_paywall_on_paywall);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mw_pre_paywall_on_paywall, mw_pre_paywall_on_paywall ? '1' : '0');
    }, [mw_pre_paywall_on_paywall]);

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title}>
                <Trans i18nKey={'analyze-responses-title'} components={[<span className={titleHighlight}></span>]} />
            </Typography>

            <div className={weightLossWithAbleChartWrapper}>
                <div className={weightLossWithAbleChartTitleWrapper}>
                    <Typography variant={TypographyVariants.h2} className={weightLossWithAbleChartTitle}>
                        <Trans i18nKey={'analyze-responses-weight-loss-with-able-chart-title'} />
                    </Typography>
                    <div className={weightLossWithAbleChartTitleValueWrapper}>
                        <Typography variant={TypographyVariants.h1} className={weightLossWithAbleChartTitleValue}>
                            <Trans i18nKey={'analyze-responses-weight-loss-with-able-chart-value-title'} />
                        </Typography>
                        <img src={pageImages[0]} className={partyIcon} alt='Party Icon' />
                    </div>
                </div>
                <img src={pageImages[1]} alt='Chart' />
                <Typography variant={TypographyVariants.h3} className={weightLossWithAbleChartInfo}>
                    <Trans i18nKey={'analyze-responses-weight-loss-with-able-chart-info'} />
                </Typography>
            </div>

            <Typography variant={TypographyVariants.p} className={progressBarTitle}>
                {t('analyze-responses-title-analyzing')}
            </Typography>

            <ThinProgressBar progress={progress} className={bar} />
        </>
    );
};
