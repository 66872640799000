export const UserEligibilityKeys = {
    isBMIValueEligible: 'isBMIValueEligible',
    userStatus: {
        approved: 'Approved',
        needsReview: 'Needs to be reviewed'
    }
};

export const VERIFICATION_STATUS = {
    PASSED: 'passed',
    NOT_PASSED: 'not-passed',
};
