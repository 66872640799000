import { ABLE_PLAN } from 'constants/able-plan';
import { LocalStorageKeys } from 'constants/local-storage';
import { AnalyticsKeys } from 'constants/analytics';
import { RoutePath } from 'routes/route-path.constant';

export const handleRedirectToPwa = (verificationStatus?: string) => {
    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]) as string;
    const encodedEmail = encodeURIComponent(email);
    const gtagClientId = localStorage.getItem(AnalyticsKeys.userId) as string;
    const encodedGtagClientId = encodeURIComponent(gtagClientId);

    let queryParams = `plan=${ABLE_PLAN}&email=${encodedEmail}&gtagClientId=${encodedGtagClientId}`;

    if (verificationStatus) {
        queryParams += `&verification=${verificationStatus}`;
    }

    window.location.href = `${process.env.REACT_APP_PWA_URL}?${queryParams}`;
};
