import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { TypographyVariants } from 'constants/typography-variants';
import { VERIFICATION_STATUS } from 'constants/user-eligibility';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

import { useStyles } from './styles';

export const MedicationGuide = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const {
        title,
        subtitle,
        listWrapper,
        bold
    } = useStyles();

    const handleClick = () => {
        pageConfiguration.event('RxWeightMedicationGuideScreenClicked');
        handleRedirectToPwa(VERIFICATION_STATUS.PASSED);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title}>
                <Trans i18nKey='medication-guide-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subtitle}>
                <Trans i18nKey='medication-guide-description' />
            </Typography>

            <div className={listWrapper}>
                <ul>
                    <li>
                        <Typography variant={TypographyVariants.p}>
                            <Trans i18nKey='medication-guide-list-item-1' components={[<span className={bold}></span>]} />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.p}>
                            <Trans i18nKey='medication-guide-list-item-2' components={[<span className={bold}></span>]} />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.p}>
                            <Trans i18nKey='medication-guide-list-item-3' components={[<span className={bold}></span>]} />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.p}>
                            <Trans i18nKey='medication-guide-list-item-4' components={[<span className={bold}></span>]} />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant={TypographyVariants.p}>
                            <Trans i18nKey='medication-guide-list-item-5' />
                        </Typography>
                    </li>
                </ul>
            </div>

            <NextButton typographyText={t('medication-guide-button-next')} onClick={handleClick} />
        </>
    );
};
