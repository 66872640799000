import { SlackWorkflowAPI } from '@ruby-labs/ui-core-kit';
import { BanPrepaidCardsKeys, PaymentKeys } from 'constants/payment';

class Primer extends SlackWorkflowAPI {

    constructor() {
        super({
            webHookUrl: process.env.REACT_APP_SLACK_WEBHOOK_PAYMENTS_MONITORING_URL as string
        });
    }

    getClientToken(data: any): Promise<{ clientToken: string, userId: string, banPrepaidCards: boolean, banPrepaidCardsEnabled: boolean }> {
        const maxRetries = 10;
        const retryDelay = 1500;

        return new Promise((resolve, reject) => {
            const attemptRequest = (retryCount: number) => {
                const startTime = performance.now();

                fetch(`${process.env.REACT_APP_ABLE_API}/payment/getClientToken`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        }

                        this.throwErrorToWebhook('[PRIMER] [getClientToken] response', response);

                        return response.json()
                            .then(json => Promise.reject(json));
                    })
                    .then((response) => {
                        localStorage.setItem(BanPrepaidCardsKeys.banPrepaidCards, response.data.banPrepaidCards);
                        localStorage.setItem(BanPrepaidCardsKeys.banPrepaidCardsEnabled, response.data.banPrepaidCardsEnabled);

                        resolve(response.data);
                    })
                    .catch((e) => {
                        const endTime = performance.now();
                        const executionTimeMs = endTime - startTime;
                        const executionTimeSec = executionTimeMs / 1000;

                        this.throwErrorToWebhook('[PRIMER] [getClientToken]', `${e.message}${e.stack}, [getClientToken] executionTimeSec: ${executionTimeSec}`);

                        if (e?.message.includes('user already exists') || e?.message.includes('user already activated')) {
                            reject(e);
                        } else if (retryCount > 0) {
                            setTimeout(() => attemptRequest(retryCount - 1), retryDelay);
                        } else {
                            console.log('[getClientToken request]', e);
                            reject(e);
                        }
                    });
            };

            attemptRequest(maxRetries);
        });
    }

    subscriptionCreate(data: any): Promise<{ customer: { id: string } }> {
        return fetch(`${process.env.REACT_APP_ABLE_API}/subscription/primer/create`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response
                .json()
                .then(json => Promise.reject(json));
        }).then((response) => {
            if (response.cookie) {
                document.cookie = response.cookie;
                localStorage.setItem(PaymentKeys.autologinPwaToken, response.cookie);
            }

            return response.data;
        }).catch((e) => {
            console.log('[subscriptionCreate request]', e, data);
            this.throwErrorToWebhook('[PRIMER] [subscriptionCreate]', e);
            return Promise.reject(e);
        });
    }

    throwErrorToWebhook(method: string, error: any) {
        return this.sendMessageToWebhook({
            url: window.location.host,
            method,
            error: JSON.stringify(error)
        });
    }
}

export const PrimerProvider = new Primer();
