import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';
import { TIMER_INITIAL_MIN, TIMER_INITIAL_SEC } from 'constants/timer';
import { PLANCODES } from 'constants/plan';
import { PaymentKeys } from 'constants/payment';

import { Program } from './program';
import { Rating } from './rating';
import { VideoCustomerReviews } from './video-customer-reviews';
import { MedicationDescription } from './medication-description';
import { ReviewsIO } from './reviews-io';
import { Expectations } from './expectations';
import { FAQ } from './faq';
import { OfferBannerWithTimer } from './offer-banner-with-timer';

import { PrePaywall } from 'pages/pre-paywall';

import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';

import { useStyles } from './styles';

export const Medication = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const isDesktop = useDesktopMediaWatcher();

    const {
        prePaywallWrapper,
        tipPageWrapper,
        childrenWrapper,
        faqWrapper,
        buttonTitleWrapper,
        buttonTitle,
        nextButtonStyles,
    } = useStyles();

    const { pageValue: minutes, setPageValue: setMinutes } = useLocalStorage({
        key: 'paywall-timer-minutes',
        defaultValue: TIMER_INITIAL_MIN,
    });

    const { pageValue: seconds, setPageValue: setSeconds } = useLocalStorage({
        key: 'paywall-timer-seconds',
        defaultValue: TIMER_INITIAL_SEC,
    });

    const { setPageValue: setPlan } = useLocalStorage({
        key: PaymentKeys.planCode,
        defaultValue: {}
    });

    const variation = useFeatureValue(ExperimentsKeys.mw_subscription_price_variations, '0');
    const trialPickerPlancodesUsed = localStorage.getItem(ExperimentsKeys.mw_trial_picker_screen) === '1';

    useEffect(() => {
        if (trialPickerPlancodesUsed) {
            return;
        }

        setPlan(PLANCODES[Number(variation)]);
    }, [trialPickerPlancodesUsed, variation]);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const mw_progress_bar_on_checkout_screen = useFeatureIsOn(ExperimentsKeys.mw_progress_bar_on_checkout_screen);
    const mw_medication_upsell = useFeatureIsOn(ExperimentsKeys.mw_medication_upsell);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mw_progress_bar_on_checkout_screen, mw_progress_bar_on_checkout_screen ? '1' : '0');
        localStorage.setItem(ExperimentsKeys.mw_medication_upsell, mw_medication_upsell ? '1' : '0');
    }, [mw_progress_bar_on_checkout_screen, mw_medication_upsell]);

    const mw_pre_paywall_on_paywall = localStorage.getItem(ExperimentsKeys.mw_pre_paywall_on_paywall) === '1';

    return (
        <>
            <OfferBannerWithTimer minutes={minutes}
                                  seconds={seconds}
                                  setMinutes={setMinutes}
                                  setSeconds={setSeconds}
            />

            {mw_pre_paywall_on_paywall &&
                <div className={prePaywallWrapper}>
                    <PrePaywall />
                </div>}

            <div className={tipPageWrapper}>
                <div className={childrenWrapper}>
                    <Program onClick={handleClick} />
                    <Rating />
                </div>
            </div>

            <div className={childrenWrapper}>
                <MedicationDescription />
            </div>

            <div>
                <VideoCustomerReviews />

                <ReviewsIO />

                <div className={childrenWrapper}>
                    <Expectations />

                    <div className={faqWrapper}>
                        <FAQ />
                    </div>
                </div>

                <div className={buttonTitleWrapper}>
                    {isDesktop && <Typography className={buttonTitle} variant={TypographyVariants.h1}>
                        {t('medication-program-tailored-program')}
                    </Typography>}
                    <NextButton buttonWrapperClassName={nextButtonStyles} onClick={handleClick}
                                typographyText={t('medication-program-button-next')} />
                </div>
            </div>
        </>
    );
};
