import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';

export const HealthCondition = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.HealthCondition];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);
    const mw_medical_director_changes_v2 = useFeatureIsOn(ExperimentsKeys.mw_medical_director_changes_v2);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mw_medical_director_changes_v2, mw_medical_director_changes_v2 ? '1' : '0');
    }, [mw_medical_director_changes_v2]);

    const multiChoicesControl: MultiChoiceType[] = [
        {
            key: 'pregnant',
            value: t('health-condition-option-pregnant'),
        },
        {
            key: 'breastfeeding',
            value: t('health-condition-option-breastfeeding'),
        },
        {
            key: 'cancer',
            value: t('health-condition-option-cancer'),
        },
        {
            key: 'eating-disorder',
            value: t('health-condition-option-eating-disorder'),
        },
        {
            key: 'substance-dependency',
            value: t('health-condition-option-substance-dependency'),
        },
        {
            key: 'diabetes',
            value: t('health-condition-option-diabetes'),
        },
        {
            key: 'bariatric-surgery',
            value: t('health-condition-option-bariatric-surgery'),
        },
        {
            key: 'pancreatitis',
            value: t('health-condition-option-pancreatitis'),
        },
        {
            key: 'thyroid-cancer',
            value: t('health-condition-option-thyroid-cancer'),
        },
        {
            key: 'none',
            value: t('health-condition-option-none'),
        },
    ];

    const multiChoicesExp: MultiChoiceType[] = [
        {
            key: 'pregnant',
            value: t('health-condition-option-pregnant'),
        },
        {
            key: 'breastfeeding',
            value: t('health-condition-option-breastfeeding'),
        },
        {
            key: 'cancer',
            value: t('health-condition-option-cancer-history'),
        },
        {
            key: 'hiv-aids',
            value: t('health-condition-option-hiv-aids'),
        },
        {
            key: 'substance-dependency',
            value: t('health-condition-option-substance-dependency'),
        },
        {
            key: 'bariatric-surgery',
            value: t('health-condition-option-bariatric-surgery'),
        },
        {
            key: 'pancreatitis',
            value: t('health-condition-option-pancreatitis'),
        },
        {
            key: 'thyroid-cancer',
            value: t('health-condition-option-thyroid-cancer'),
        },
        {
            key: 'none',
            value: t('health-condition-option-none'),
        },
    ];

    const multiChoices = mw_medical_director_changes_v2 ? multiChoicesExp : multiChoicesControl;
    const multiChoicesByGender: MultiChoiceType[] = userGender === 'male' ? multiChoices.slice(2) : multiChoices;

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoicesByGender.find((option) => option.key === item);

            return currentOption?.value;
        });
        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('health-condition-title'),
                value: pageValue[0] === 'none'
                    ?
                    multiChoicesByGender
                        .filter(multiChoice => multiChoice.key !== 'none')
                        .map(multiChoice => `"${multiChoice.value}" - NO`)
                    :
                    selectOption,
            },
        });

        const redirectUserToDeclineScreen = mw_medical_director_changes_v2 ?
            pageValue[0] !== 'none'
            :
            (pageValue.includes('pregnant') || pageValue.includes('breastfeeding'));
        pageConfiguration.handleOnPageComplete({ pageValue, declineUser: redirectUserToDeclineScreen });
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('health-condition-title')}
            </Typography>

            {multiChoicesByGender.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    disabled={pageValue[0] === 'none' && v.key !== 'none'}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
