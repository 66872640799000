import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { MedicalSubscriptionPaymentResult, UsersApiProvider } from 'api/users.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { TypographyVariants } from 'constants/typography-variants';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { MEDICATION_PLANS } from 'constants/medication-plan';
import { CUSTOMER_ID } from 'constants/payment';

import { NextButton } from 'components/next-button';
import { Typography } from 'components/typography';

import { OrderDeclinedBlock } from 'pages/medication-plan/order-declined-block';
import { MedicationProgramSelector } from 'pages/medication-plan/medication-program-selector';

import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

import { useStyles } from './styles';

export const MedicationPlan = () => {
    const { t } = useTranslation();

    const { pageWrapper, title, listWrapper, multiChoiceWrapper, link, confirmOrderButton, skipButton } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: medicationPlan, setPageValue: setMedicationPlan } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.MedicationPlan],
        defaultValue: MEDICATION_PLANS[0],
    });

    const firstName = localStorage.getItem(MultipleLocalStorageKeys[RoutePath.Checkout].firstName as string);
    const lastName = localStorage.getItem(MultipleLocalStorageKeys[RoutePath.Checkout].lastName as string);
    const userId = localStorage.getItem(CUSTOMER_ID) as string;
    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]) as string;

    const termsLink =
        <a className={link} href='https://ableapp.com/terms-conditions' target='_blank' rel='noreferrer nofollow'>
            Medication Terms & Conditions
        </a>;

    const termsPrivacyPolicyText = <Trans i18nKey='medication-plan-terms-and-conditions'
                                          components={[termsLink]} /> as unknown as string;

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'terms-and-conditions',
            label: termsPrivacyPolicyText,
        },
    ];

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [declineBlockShown, setDeclineBlockShown] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [medicalSubscriptionExists, setMedicalSubscriptionExists] = useState<boolean>(false);
    const [medicalSubscriptionFailed, setMedicalSubscriptionFailed] = useState<boolean>(false);

    useEffect(() => {
        UsersApiProvider
            .getMedicalSubscription()
            .then((response: MedicalSubscriptionPaymentResult) => {
                pageConfiguration.event('RxWeightMedicationPlanAlreadyExistsViewed', { MedPlanResponse: response });
                setMedicalSubscriptionExists(true);
            })
            .catch(error => {
                setMedicalSubscriptionExists(false);
                pageConfiguration.event('RxWeightMedicationPlanViewed');
                console.error('Medical subscription not found:', error);
            });

    }, []);

    const handleAcceptMedicationTermsClicked = () => {
        pageConfiguration.event('RxWeightMedicationPlanAcceptMedicationTermsClicked');
        setTermsAccepted(!termsAccepted);
    };

    const handleConfirm = () => {
        pageConfiguration.event('RxWeightMedicationPlanConfirmOrderClicked');

        if (!termsAccepted) {
            pageConfiguration.event('RxWeightMedicationPlanOrderDeclineViewed');
            setDeclineBlockShown(true);
            return;
        } else {
            setDeclineBlockShown(false);
        }

        setLoading(true);

        UsersApiProvider
            .processMedicalSubscription({ productId: medicationPlan.productId, bundleId: medicationPlan.bundleId })
            .then((response: MedicalSubscriptionPaymentResult) => {
                if (response.status !== 'payment_failed') {
                    setMedicalSubscriptionFailed(false);
                    setLoading(false);
                    pageConfiguration.event('RxWeightMedicationPlanPurchaseSuccess', {
                        PaymentStatus: 'Success',
                        email: email,
                        user_id: userId,
                        planKey: medicationPlan.key,
                        productId: medicationPlan.productId,
                        bundleId: medicationPlan.bundleId
                    });
                    pageConfiguration.handleOnPageComplete();
                } else {
                    pageConfiguration.event('RxWeightMedicationPlanPurchaseFailed', {
                        PaymentStatus: 'Error',
                        email: email,
                        user_id: userId,
                        planKey: medicationPlan.key,
                        productId: medicationPlan.productId,
                        bundleId: medicationPlan.bundleId
                    });
                    handleRedirectToPwa();
                }
            })
            .catch(error => {
                setLoading(false);
                setMedicalSubscriptionFailed(true);
                setDeclineBlockShown(true);
                pageConfiguration.event('RxWeightMedicationPlanPurchaseFailed', {
                    PaymentStatus: 'Error',
                    PaymentError: error,
                    email: email,
                    user_id: userId,
                    planKey: medicationPlan.key,
                    productId: medicationPlan.productId,
                    bundleId: medicationPlan.bundleId
                });
                console.error('Error creating medical subscription:', error);
            });
    };

    const handleSkipClick = () => {
        pageConfiguration.event('RxWeightMedicationPlanContinueAndChooseLaterClicked');
        handleRedirectToPwa();
    };

    const handleMedPlanAlreadyExists = () => {
        pageConfiguration.event('RxWeightMedicationPlanAlreadyExistsClicked');
        handleRedirectToPwa();
    };

    const orderDeclinedBlockTitle = termsAccepted && medicalSubscriptionFailed ? 'order-declined-block-payment-failed-title' : 'order-declined-block-terms-not-accepted-title';
    const orderDeclinedBlockDescription = termsAccepted && medicalSubscriptionFailed ? 'order-declined-block-payment-failed-description' : 'order-declined-block-terms-not-accepted-description';

    return (
        <div className={pageWrapper}>
            {medicalSubscriptionExists
                ?
                <>
                    <Typography variant={TypographyVariants.h1}>
                        <Trans i18nKey='medication-plan-already-exists' />
                    </Typography>
                    <NextButton
                        typographyText={t('medication-plan-already-exists-button')}
                        onClick={handleMedPlanAlreadyExists}
                    />
                </>
                :
                <>
                    <Typography variant={TypographyVariants.h1} className={title}>
                        <Trans i18nKey='medication-plan-title' values={{ firstName, lastName }} />
                    </Typography>

                    <MedicationProgramSelector medicationPlan={medicationPlan} setMedicationPlan={setMedicationPlan}
                                               medicationPlans={MEDICATION_PLANS} />

                    <div className={listWrapper}>
                        <Typography variant={TypographyVariants.p}>
                            <Trans i18nKey='medication-plan-info-list-title' />
                        </Typography>

                        <ul>
                            <li>
                                <Typography variant={TypographyVariants.p}>
                                    <Trans i18nKey='medication-plan-info-list-item-1' />
                                </Typography>
                            </li>
                            <li>
                                <Typography variant={TypographyVariants.p}>
                                    <Trans i18nKey='medication-plan-info-list-item-2' />
                                </Typography>
                            </li>
                            <li>
                                <Typography variant={TypographyVariants.p}>
                                    <Trans i18nKey='medication-plan-info-list-item-3' />
                                </Typography>
                            </li>
                        </ul>

                        <div className={multiChoiceWrapper}>
                            <MultiChoice
                                options={multiChoices}
                                selectedOptions={termsAccepted ? multiChoices : []}
                                onSelect={handleAcceptMedicationTermsClicked}
                            />
                        </div>

                        <Typography variant={TypographyVariants.p}>
                            <Trans i18nKey='medication-plan-payment-info'
                                   values={{ medicationPlanPrice: medicationPlan.subscriptionAmount / 100 }} />
                        </Typography>
                    </div>

                    {declineBlockShown && <OrderDeclinedBlock title={orderDeclinedBlockTitle}
                                                              description={orderDeclinedBlockDescription} />}

                    <NextButton
                        className={confirmOrderButton}
                        typographyText={t('medication-plan-confirm-order-button')}
                        loading={loading}
                        onClick={handleConfirm}
                    />
                    <NextButton
                        className={skipButton}
                        typographyText={t('medication-plan-skip-button')}
                        onClick={handleSkipClick}
                    />
                </>
            }
        </div>
    );
};
