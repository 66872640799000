import React, { FC } from 'react';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { MedicationPlanType } from 'constants/medication-plan';

import { RadioButton } from './radio-button';

import { useStyles } from './styles';

const IMAGE_PATHS = ['webp/medication-plan/sema.webp', 'webp/medication-plan/bella.webp', 'svg/medication-plan/star.svg'];

interface MedicationProgramSelectorProps {
    medicationPlan: MedicationPlanType;
    setMedicationPlan: (value: MedicationPlanType) => void;
    medicationPlans: MedicationPlanType[];
}

export const MedicationProgramSelector: FC<MedicationProgramSelectorProps> = ({ medicationPlan, setMedicationPlan, medicationPlans }) => {
    const { contentWrapper} = useStyles();

    const pageImages = useBackgroundImageService(IMAGE_PATHS);

    return (
        <div className={contentWrapper}>
            {medicationPlans.map((plan: MedicationPlanType, index) => (
                <RadioButton
                    key={plan.key}
                    badgeImgSrc={pageImages[2]}
                    badgeTitle={plan.badgeTitle}
                    imageSrc={pageImages[index]}
                    title={plan.title}
                    description={plan.description}
                    checked={medicationPlan.amount === plan.amount}
                    onClick={() => { setMedicationPlan(plan) }}
                    medicationPlanPrice={plan.amount / 100}
                />
            ))}
        </div>
    );
};
