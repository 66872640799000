import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    sectionsList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '24px',
        position: 'relative',

        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '4px',
        },

        '&::before': {
            content: '" "',
            position: 'absolute',
            height: '1px',
            width: '80%',
            zIndex: '-1',
            top: '44%',
            background: '#C5C5D1',
            marginLeft: '10%'
        }
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        background: '#F6F6F6',
        border: '0.684722px solid #C5C5D1',

        '& > h3': {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            color: '#C5C5D1'
        }
    },
    image: {
        opacity: 0
    },
    shown: {
        opacity: 1
    },
    sectionName: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#C5C5D1',
        margin: 'unset'
    },
    sectionNameActive: {
        color: '#5E626B'
    },
    active: {
        background: '#36B992',
        border: 'none',

        '& > h3': {
            color: '#FFFFFF'
        }
    },
    '@media (min-width: 1000px)' : {
        sectionsList: {

            '&::before': {
                width: '90%',
                marginLeft: '5%'
            }
        }
    },
    '@media (min-width: 768px)' : {
        sectionsList: {
            marginBottom: '40px'
        }
    },
    '@media (max-width: 540px)' : {
        sectionsList: {

            '&::before': {
                width: '90%',
                marginLeft: '5%'
            }
        }
    },
    '@media (max-width: 430px)' : {
        sectionsList: {

            '&::before': {
                width: '85%',
                marginLeft: '10%'
            }
        }
    },
});
