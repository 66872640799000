import React from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { ListItem } from 'pages/how-sema-works/content-v2/list-item';

import { useStyles } from './styles';

export const ContentV2 = () => {
    const { contentWrapper } = useStyles();

    const pageImages = useBackgroundImageService([
        'png/how-sema-works/brain.png',
        'png/how-sema-works/heart.png',
        'png/how-sema-works/liver.png',
        'png/how-sema-works/stomach.png',
        'png/how-sema-works/pancreas.png',
    ]);

    const listItemsData = [
        {
            image: pageImages[0],
            titleKey: 'how-sema-works-list-item-brain-title',
            descriptionKey: 'how-sema-works-list-item-brain-description',
        },
        {
            image: pageImages[1],
            titleKey: 'how-sema-works-list-item-heart-title',
            descriptionKey: 'how-sema-works-list-item-heart-description',
        },
        {
            image: pageImages[2],
            titleKey: 'how-sema-works-list-item-liver-title',
            descriptionKey: 'how-sema-works-list-item-liver-description',
        },
        {
            image: pageImages[3],
            titleKey: 'how-sema-works-list-item-stomach-title',
            descriptionKey: 'how-sema-works-list-item-stomach-description',
        },
        {
            image: pageImages[4],
            titleKey: 'how-sema-works-list-item-pancreas-title',
            descriptionKey: 'how-sema-works-list-item-pancreas-description',
        },
    ];

    return (
        <div className={contentWrapper}>
            <Typography variant={TypographyVariants.h2}>
                <Trans i18nKey='how-sema-works-description' />
            </Typography>

            <div>
                <div>
                    {listItemsData.slice(0, 3).map((item, index) => (
                        <ListItem key={index} {...item} />
                    ))}
                </div>

                <div>
                    {listItemsData.slice(3).map((item, index) => (
                        <ListItem key={index + 3} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};
