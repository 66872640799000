import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    title: {
        marginBottom: '16px'
    },
    subtitle: {
        margin: '0 0 16px',
    },
    listWrapper: {

        '& p': {
            fontSize: '18px',
            lineHeight: '26px',
            textAlign: 'left',
            fontWeight: 400,
            margin: '0 0 16px',
        },

        '& > ul': {
            listStyle: 'disc',
            listStylePosition: 'outside',
            marginLeft: '16px',

            '& > li:last-child > p': {
                margin: '0 0 12px',
            }
        }
    },
    bold: {
        fontWeight: 600
    },
    '@media (min-width: 768px)' : {
        title: {
            marginBottom: '32px'
        },
        subtitle: {
            margin: '0 0 32px'
        },
        listWrapper: {

            '& > ul': {

                '& > li:last-child > p': {
                    margin: '0 0 56px',
                }
            }
        }
    }
});
