import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { UsersApiProvider } from 'api/users.api';

import { TypographyVariants } from 'constants/typography-variants';
import { VERIFICATION_STATUS } from 'constants/user-eligibility';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { CheckPointsList } from 'components/check-points-list';

import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

import { useStyles } from './styles';

const LIST_ITEMS = [
    'identity-verification-list-item-1', 'identity-verification-list-item-2',
    'identity-verification-list-item-3'
];

export const IdentityVerification = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const {
        title,
        subtitle,
        listWrapper,
        listItem,
        listItemText,
        beginVerificationButton,
        verifyLaterButton
    } = useStyles();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const verificationStatus = params.get('verification');

        if (!verificationStatus) {
            pageConfiguration.event('RxWeightIdentityScreenViewed');
        }

        if (verificationStatus === VERIFICATION_STATUS.NOT_PASSED) {
            pageConfiguration.event('RxWeightIdentityScreenViewed2');
            pageConfiguration.event('RxWeightIdentificationFailed');
            handleRedirectToPwa(verificationStatus);
        }

        if (verificationStatus === VERIFICATION_STATUS.PASSED) {
            pageConfiguration.event('RxWeightIdentityScreenViewed2');
            pageConfiguration.event('RxWeightIdentificationSuccess');
            pageConfiguration.handleOnPageComplete();
        }
    }, []);

    const handleBeginVerificationClick = () => {
        pageConfiguration.event('RxWeightIdentityScreenBeginVerificationClicked');

        UsersApiProvider
            .identifyUrl({
                force: true,
                successRedirectUrl: `${window.location.origin}${window.location.pathname}?verification=${VERIFICATION_STATUS.PASSED}`,
                failureRedirectUrl: `${window.location.origin}${window.location.pathname}?verification=${VERIFICATION_STATUS.NOT_PASSED}`,
            })
            .then((response) => {
                window.location.replace(response.url);
            });
    };

    const handleVerifyLaterClick = () => {
        pageConfiguration.event('RxWeightIdentityScreenContinueAndVerifyLaterClicked');
        handleRedirectToPwa();
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title}>
                <Trans i18nKey='identity-verification-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subtitle}>
                <Trans i18nKey='identity-verification-description' />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subtitle}>
                <Trans i18nKey='identity-verification-list-title' />
            </Typography>

            <div className={listWrapper}>
                <CheckPointsList items={LIST_ITEMS} listItemClassName={listItem} listItemTextClassName={listItemText} />
            </div>

            <NextButton className={beginVerificationButton} typographyText={t('identity-verification-begin-verification-button')} onClick={handleBeginVerificationClick} />
            <NextButton className={verifyLaterButton} typographyText={t('identity-verification-verify-later-button')} onClick={handleVerifyLaterClick} />
        </>
    );
};
