import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    title: {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
        margin: '0 4px 0 0',
    },
    timer: {
        minWidth: '40px',

        '& > span': {
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 500,
        }
    },
    '@media (min-width: 768px)': {
        title: {
            fontSize: '16px',
            lineHeight: '18px',
        },
        timer: {

            '& > span': {
                fontSize: '16px',
                lineHeight: '18px',
            }
        }
    }
});
