import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { pageConfiguration } from 'containers/index';

import { RoutePath } from 'routes/route-path.constant';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { useStyles as ContentUseStyles } from 'containers/content/styles';

export const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mainContent } = ContentUseStyles({ scrollableContent: false });
    const handleClick = () => {
        pageConfiguration.event('RxWeightNotFoundPageClicked');
        navigate(`..${RoutePath.Main}`);
    };

    return (
        <div className={mainContent}>
            <Typography variant={TypographyVariants.h1}>
                <Trans i18nKey='not-found-title' />
            </Typography>
            <Typography variant={TypographyVariants.h2}>
                <Trans i18nKey='not-found-description' />
            </Typography>

            <NextButton onClick={handleClick} typographyText={t('not-found-next-button')} />
        </div>
    );
};
