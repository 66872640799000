import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { Banner } from 'components/banner';

import { TypographyVariants } from 'constants/typography-variants';
import { Typography } from 'components/typography';

import { useStyles } from './styles';

interface TipBannerProps {
    textKey: string;
}

export const TipBanner: FC<TipBannerProps> = ({ textKey }): JSX.Element => {
    const { tipBannerText } = useStyles();

    return (
        <Banner>
            <Typography variant={TypographyVariants.h3} className={tipBannerText}>
                <Trans i18nKey={textKey} components={[<span></span>]} />
            </Typography>
        </Banner>
    );
};
