export const CUSTOMER_ID = 'customerid';

export const BanPrepaidCardsKeys  = {
    banPrepaidCards: 'banPrepaidCards',
    banPrepaidCardsEnabled: 'banPrepaidCardsEnabled'
};

export const PaymentKeys = {
    planCode: 'planCode',
    autologinPwaToken: 'autologinPwaToken'
};
