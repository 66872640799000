import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    prePaywallWrapper: {

        '& > div': {
            background: '#FFFFFF'
        },

        '& > div > div[class^="banner"]': {
            display: 'none'
        },

        '& > div > div[class^="floatedButtonWrapper"]': {
            display: 'none'
        },

        '& > div > div[class^="headerWrapper"]': {
            margin: '16px 0 0',
        },

        '& > div > div[class^="childrenWrapper"] > div:last-child': {
            margin: '16px 0 32px'
        },

        '@media (min-width: 768px)' : {

            '& > div > div[class^="headerWrapper"]': {
                margin: '0 0 8px',

                '& > div[class^="medicalDirectorCardWrapper"]': {

                    '& > div > h2': {
                        fontSize: '12px',
                        lineHeight: '16px'
                    }
                }
            },

            '& > div > div[class^="childrenWrapper"] > div:last-child': {
                margin: '24px 0 0'
            },
        }
    },
    tipPageWrapper: {
        background: '#F6F6F6',
    },
    childrenWrapper: {
        maxWidth: '608px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0px 16px 0px',
        margin: '0px auto',
        overflowX: 'hidden',
        position: 'relative'
    },
    faqWrapper: {
        marginTop: '32px',
        marginBottom: '20px',
    },
    buttonTitleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'sticky',
        bottom: 0,
        left: '50%',
        zIndex: 5,
        maxWidth: 'inherit',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 21.6%)',
    },
    buttonTitle: {
        lineHeight: '32px',
        margin: 0
    },
    nextButtonStyles: {
        padding: '16px'
    },
    '@media (min-width: 768px)': {
        childrenWrapper: {
            maxWidth: '800px'
        },
        faqWrapper: {
            marginBottom: '96px',
        },
        nextButtonStyles: {
            width: '375px',
            marginLeft: '40px'
        }
    }
});
