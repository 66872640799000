import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 16px'
    },
    fishImage: {
        width: '100%',
    },
    ableLogo: {
        margin: '32px 0 -16px'
    },
    wrapper: {
        width: '100%',
        maxWidth: '560px'
    },
    '@media (min-width: 768px)': {
        pageWrapper: {
            padding: 0
        },
        ableLogo: {
            margin: '80px 0 -40px'
        },
    }
});
