import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    cartWrapper: {
        padding: '16px',
        background: '#EAECF1',
    },
    headerInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: '24px',

        '& img': {
            marginRight: '12px'
        }
    },
    cartTitle: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 600,
        margin: '0 12px 0 0'
    },
    divider: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #C5C5D1',
        padding: '0 0 12px',

        '&:last-of-type': {
            border: 'none',
            padding: '12px 0 0',
        },

        '& img': {
            width: '60px',
            height: '60px',
            marginRight: '16px',
            borderRadius: '8px'
        }
    },
    borderTop: {
        borderTop: '1px solid #C5C5D1'
    },
    infoBlockWithImage: {
        display: 'flex',
        flexDirection: 'row'
    },
    infoBlock: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontWeight: 600,
        textAlign: 'left',
        margin: '0 0 8px'
    },
    subtitle: {
        fontSize: '11px',
        lineHeight: '14px',
        textAlign: 'left',
        margin: 'unset',
    },
    blockTitle: {
        lineHeight: '22px',
        margin: 'unset',
        textAlign: 'left'
    },
    boldTitle: {
        fontWeight: 600
    },
    price: {
        lineHeight: '24px',
        fontWeight: 600,
        margin: 'unset'
    },
    fullPrice: {},
    period: {
        fontSize: '12px',
        lineHeight: '24px',
        color: 'rgba(94, 98, 107, 1)',
        fontWeight: 600
    },
    total: {
        lineHeight: '22px'
    },
    trial: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'rgba(94, 98, 107, 1)',
        fontWeight: 400
    },
    '@media (min-width: 768px)' : {
        cartWrapper: {
            maxWidth: '420px',
            padding: 0
        },
        cartTitle: {
            fontSize: '20px',
            lineHeight: '24px',
            marginRight: 0
        },
        divider: {
            padding: '24px 0',

            '&:last-of-type': {
                padding: '24px 0',
            },

            '& img': {
                width: '80px',
                height: '80px'
            }
        },
        title: {
            fontSize: '18px',
            lineHeight: '24px',
            margin: '0 0 12px'
        },
        subtitle: {
            fontSize: '14px',
            lineHeight: '20px'
        },
        blockTitle: {
            fontSize: '16px',
            lineHeight: '24px'
        },
        boldTitle: {
            fontSize: '20px'
        },
        price: {
            fontSize: '16px',

            '& first-of-type': {
                fontSize: '18px'
            }
        },
        fullPrice: {
            fontSize: '18px'
        },
        period: {
            fontSize: '14px'
        },
        total: {
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: 600
        },
        trial: {
            fontSize: '20px',
            lineHeight: '24px'
        }
    }
});
