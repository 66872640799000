import React from 'react';
import { Trans } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

export const UserExistsMessage = (): JSX.Element => {

    return (
        <div>
            <Typography variant={TypographyVariants.h3}>
                <Trans i18nKey='checkout-user-exists-message' />
            </Typography>

            <NextButton onClick={handleRedirectToPwa} />
        </div>
    );
};
