import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ tipPage }: ThemeProps) => ({
    pageWrapper: {
        ...tipPage.tipPageWrapper,

        '& > h1': {
            margin: '48px 0 32px',
        }
    },
    orange: {
        color: '#EB731A'
    },
    '@media (min-width: 768px)' : {
        pageWrapper: {

            '& > h1': {
                fontSize: '28px',
                margin: '80px 0 40px',
            }
        },
    },
}));
