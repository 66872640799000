import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

interface ListItemProps {
    image: string;
    titleKey: string;
    descriptionKey: string;
}

export const ListItem: FC<ListItemProps> = ({ image, titleKey, descriptionKey }) => {

    return (
        <div>
            <img src={image} alt={titleKey} />
            <Typography variant={TypographyVariants.p}>
                <Trans i18nKey={titleKey} />
            </Typography>
            <Typography variant={TypographyVariants.p}>
                <Trans i18nKey={descriptionKey} />
            </Typography>
        </div>
    );
};
