// node modules
import { createUseStyles } from 'react-jss'

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ button }: ThemeProps) => ({
    buttonWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        "& $buttonDisabled": {
            ...button.buttonDisabled,
        },
        "& $marginBottom": {
            marginBottom: '5vh',
        }
    },
    button: button.button,
    buttonDisabled: button.buttonDisabled,
    marginBottom: {},
    loadingButton: {
        cursor: 'none',
        pointerEvents: 'none'
    }
}));
