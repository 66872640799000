// node modules
import React, { FC, ReactNode } from 'react';
// local files
import { TypographyVariants } from 'constants/typography-variants';
import { useStyles } from './styles';

export interface TypographyProps {
    variant: TypographyVariants;
    typographyNext?: boolean;
    className?: string;
    children?: string | ReactNode;
    style?: any;
    onAnimationStart?: () => void;
    onAnimationEnd?: () => void;
}

export const Typography: FC<TypographyProps> = ({
        variant,
        className,
        typographyNext,
        children,
        ...props
    }) => {

    const classes: any = useStyles();

    const variantClassName = classes[variant] ? classes[variant] : 'p';
    let typographyClassName = `${variantClassName}`;

    // @TODO: Use overrides for this from themeProvider
    if (typographyNext) {
        typographyClassName += ` ${classes.marginBottom16}`;
    }

    if (className) {
        typographyClassName += ` ${className}`;
    }

    if (variant === TypographyVariants.h1) {
        return <h1 className={typographyClassName} {...props}>{children}</h1>
    }

    if (variant === TypographyVariants.h2) {
        return <h2 className={typographyClassName} {...props}>{children}</h2>
    }

    if (variant === TypographyVariants.h3) {
        return <h3 className={typographyClassName} {...props}>{children}</h3>
    }

    if (variant === TypographyVariants.tab) {
        return <span className={typographyClassName} {...props}>{children}</span>
    }

    if ([TypographyVariants.button, TypographyVariants.choiceButton, TypographyVariants.multiChoiceButton].includes(variant)) {
        return <span className={typographyClassName} {...props}>{children}</span>
    }

    if ([TypographyVariants.inputLabel, TypographyVariants.inputStartAdornment, TypographyVariants.inputEndAdornment].includes(variant)) {
        return <span className={typographyClassName} {...props}>{children}</span>
    }

    if ([TypographyVariants.tipPage].includes(variant)) {
        return <span className={typographyClassName} {...props}>{children}</span>
    }

    return <p className={typographyClassName} {...props}>{children}</p>
};


