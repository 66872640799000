import React, { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';
import { UsersApiProvider } from 'api/users.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { UserVerificationKeys } from 'constants/user-verification';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { ValidatedInput } from 'components/validated-input';

import { useStyles } from './styles';

export const Email = () => {
    const { t } = useTranslation();

    const { agreementText } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: email, setPageValue: setEmail } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email],
        defaultValue: '',
    });

    const handleClick = () => {
        UsersApiProvider
            .isUserExists(email)
            .then(({ isUserExists }) => {
                localStorage.setItem(UserVerificationKeys.isUserExists, String(isUserExists));
                pageConfiguration.handleOnPageComplete({ email, isUserExists });
            });
    };

    const [pageError, setPageError] = useState(false);

    const onChange = (e: any) => {
        setEmail(e.target.value);
    };

    const onError = (error: boolean) => {
        setPageError(error);
    };

    const mw_trial_picker_screen = useFeatureIsOn(ExperimentsKeys.mw_trial_picker_screen);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mw_trial_picker_screen, mw_trial_picker_screen ? '1' : '0');
    }, [mw_trial_picker_screen]);

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext>
                    <Trans i18nKey='email-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                <Trans i18nKey='email-subtitle' />
            </Typography>

            <ValidatedInput
                value={email}
                onChange={onChange}
                onError={onError}
                validationQuery={{
                    type: {
                        value: 'email',
                        errorMessage: t('email-input-error-message'),
                    },
                }}
                label={t('email-input-label')}
            />

            <Typography variant={TypographyVariants.p} className={agreementText}>
                <Trans i18nKey='email-text' />
            </Typography>

            <NextButton
                onClick={handleClick}
                typographyText={t('email-button-next')}
                disabled={!email || pageError}
            />
        </>
    );
};
