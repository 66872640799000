import { SlackWorkflowAPI } from '@ruby-labs/ui-core-kit';
import { PaymentKeys } from 'constants/payment';

export interface MedicalSubscriptionPaymentResult {
    id: string;
    status: string;
    userVerificationStatus: string;
}

class UsersApi extends SlackWorkflowAPI {

    constructor() {
        super({
            webHookUrl: process.env.REACT_APP_SLACK_WEBHOOK_PAYMENTS_MONITORING_URL as string
        });
    }

    isUserExists(email: string): Promise<{ isUserExists: boolean }> {

        if (!email) {
            return Promise.resolve({ isUserExists: false });
        }

        return fetch(`${process.env.REACT_APP_ABLE_API}/users/isUserActive?email=${encodeURIComponent(email)}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response
                    .json()
                    .then(json => Promise.reject(json));
            }).then((response) => {
                return response.data;
            }).then(({ isUserActive }) => {
                // @TODO: Refactor it, (for now hardcode) because function changes
                return { isUserExists: isUserActive };
            }).catch((e) => {
                console.log('[isUserExists request]', e);
                return Promise.resolve({ isUserExists: false });
            });
    }

    identifyUrl(data: { force: boolean, successRedirectUrl: string, failureRedirectUrl: string }): Promise<{
        url: string
    }> {
        const autologinPwaToken = localStorage.getItem(PaymentKeys.autologinPwaToken) as string;

        return fetch(`${process.env.REACT_APP_ABLE_USERS_API}/identify/url`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${autologinPwaToken.split(';')[0].split('=')[1]}`,
                'Content-Type': 'application/json',
                'X-Auth': process.env.REACT_APP_ABLE_API_KEY as string,
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response
                .json()
                .then(json => Promise.reject(json));
        }).then((response) => {
            return response;
        }).catch((e) => {
            console.log('[identifyUrl request]', e, data);
            this.throwErrorToWebhook('[USERS] [identifyUrl]', e);
            return Promise.reject(e);
        });
    }

    getMedicalSubscription(): Promise<MedicalSubscriptionPaymentResult> {
        const autologinPwaToken = localStorage.getItem(PaymentKeys.autologinPwaToken) as string;

        return fetch(`${process.env.REACT_APP_ABLE_USERS_API}/medical/subscription`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${autologinPwaToken.split(';')[0].split('=')[1]}`,
                'Content-Type': 'application/json',
                'X-Auth': process.env.REACT_APP_ABLE_API_KEY as string,
            },
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response
                .json()
                .then(json => Promise.reject(json));
        }).then((response) => {
            return response;
        }).catch((e) => {
            console.log('[getMedicalSubscription request]', e);
            this.throwErrorToWebhook('[USERS] [getMedicalSubscription]', e);
            return Promise.reject(e);
        });
    }

    createMedicalSubscription(data: { productId: number, bundleId: number }): Promise<{
        status: number;
        message: string;
    }> {
        const autologinPwaToken = localStorage.getItem(PaymentKeys.autologinPwaToken) as string;

        return fetch(`${process.env.REACT_APP_ABLE_USERS_API}/medical/subscription`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${autologinPwaToken.split(';')[0].split('=')[1]}`,
                'Content-Type': 'application/json',
                'X-Auth': process.env.REACT_APP_ABLE_API_KEY as string,
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response
                .json()
                .then(json => Promise.reject(json));
        }).then((response) => {

            return response;
        }).catch((e) => {
            console.log('[createMedicalSubscription request]', e, data);
            this.throwErrorToWebhook('[USERS] [createMedicalSubscription]', e);
            return Promise.reject(e);
        });
    }

    processMedicalSubscription(data: { productId: number, bundleId: number }): Promise<MedicalSubscriptionPaymentResult> {

        return this.createMedicalSubscription(data).then(() => {
            const fetchPaymentStatus = (): Promise<MedicalSubscriptionPaymentResult> => {
                return this.getMedicalSubscription().then((paymentResult: MedicalSubscriptionPaymentResult) => {
                    if (paymentResult.status === 'processing') {
                        return new Promise(resolve => setTimeout(resolve, 1000)).then(fetchPaymentStatus);
                    } else {
                        return paymentResult;
                    }
                }).catch((e) => {
                    console.log('[processMedicalSubscription request]', e);
                    this.throwErrorToWebhook('[USERS] [processMedicalSubscription]', e);
                    return Promise.reject(e);
                });
            };

            return fetchPaymentStatus();
        });
    }

    throwErrorToWebhook(method: string, error: any) {
        return this.sendMessageToWebhook({
            url: window.location.host,
            method,
            error: JSON.stringify(error)
        });
    }
}

export const UsersApiProvider = new UsersApi();
