import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    pageWrapper: {
        margin: '0 auto',
        maxWidth: '560px',
        padding: '0 16px',
    },
    title: {
        textAlign: 'left',
        margin: 'unset',
        padding: '32px 0 16px'
    },
    paragraph: {
        fontSize: '16px',
        lineHeight: '18px',
        textAlign: 'left',
        margin: 'unset',
        paddingBottom: '12px',

        '& em': {
            fontWeight: 600,
        },
    },
    lastParagraph: {
        paddingBottom: 0,
    },
    ratingButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80.5px',
        padding: '16px',
        minWidth: '65px',
    },
    supportOptionDescriptionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '8px 0 12px',
    },
    smallerBottomIndent: {
        margin: '8px 0 4px',
    },
    supportOption: {
        lineHeight: '20px',
        color: '#868C99',
        textAlign: 'left',
    },
    arrowImage: {
        paddingRight: '3%',
    },
    bestSupportOption: {
        textAlign: 'left',
        fontWeight: 600,
        color: '#1375D6',
        margin: '0 0 12px',
    },
    ratingButtonText: {},
    nextButton: {
        ...nextButton.nextButton,
        '&:active': {
            ...nextButton.nextButtonHovered,
        }
    },
    '@media screen and (max-width: 360px)': {
        ratingButton: {
            width: '70.5px',
        },
        ratingButtonText: {
            fontSize: '16px',
        }
    },
    '@media screen and (max-width: 320px)': { // iPhone 4 resolution
        ratingButton: {
            width: '65px',
        },
        ratingButtonText: {
            fontSize: '15px',
        }
    },
    '@media screen and (max-width: 280px)': { // Galaxy Fold resolution
        ratingButton: {
            width: '58px',
            minWidth: '58px'
        }
    },
    '@media screen and (min-width: 768px)': {
        pageWrapper: {
            padding: 0,
        },
        title: {
            fontSize: '28px',
            lineHeight: '33px',
            textAlign: 'center',
            margin: 'unset',
            padding: '40px 0 16px'
        },
        paragraph: {
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'center',
        },
        lastParagraph: {
            paddingBottom: 0,
        },
        ratingButton: {
            width: '100%',
            height: '72px',
            padding: '24px 35.5px',
            maxWidth: '128px'
        },
        ratingButtonText: {
            fontSize: '20px',
        },
        supportOptionDescriptionWrapper: {
            margin: '16px 0 12px',
        },
        smallerBottomIndent: {
            margin: '16px 0 4px',
        },
        supportOption: {
            fontSize: '18px',
            lineHeight: '28px',
        },
        arrowImage: {
            width: '127px',
            height: '58px',
            paddingRight: '6%',
        },
        bestSupportOption: {
            fontSize: '18px',
            lineHeight: '28px',
        },
        nextButton: {
            height: '56px',
        }
    },
    '@media (hover: hover)': {
        nextButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            },
        }
    }
}));
