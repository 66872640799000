import { DEFAULT_PAGES_CONFIGURATION } from 'services/default-pages-configuration.service';
import { RoutePath } from 'routes/route-path.constant';

import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';

import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';


export const ABPagesConfiguration = () => {
    let finalConfiguration = Object.assign({}, DEFAULT_PAGES_CONFIGURATION);
    const mw_plan_loader_screen = localStorage.getItem(ExperimentsKeys.mw_plan_loader_screen);
    const mw_email_screen_removal = localStorage.getItem(ExperimentsKeys.mw_email_screen_removal);
    const mw_how_sema_works = localStorage.getItem(ExperimentsKeys.mw_how_sema_works);
    const mw_trial_picker_screen = localStorage.getItem(ExperimentsKeys.mw_trial_picker_screen);
    const mw_medical_director_changes_v2 = localStorage.getItem(ExperimentsKeys.mw_medical_director_changes_v2);
    const mw_pre_paywall_on_paywall = localStorage.getItem(ExperimentsKeys.mw_pre_paywall_on_paywall);
    const mw_medication_upsell = localStorage.getItem(ExperimentsKeys.mw_medication_upsell);

    if (mw_plan_loader_screen === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.Email]: {
                ...finalConfiguration[RoutePath.Email],
                route: {
                    next: (data: any) => {
                        if (data?.isUserExists) {
                            handleRedirectToPwa();
                        }

                        return RoutePath.PlanLoader;
                    },
                },
            },
            [RoutePath.PlanLoader]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: false,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.PrePaywall,
                },
                events: {
                    onLeave: () => 'RxWeightAnalyzeResponsesCompleted',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyGender],
                    LocalStorageKeys[RoutePath.LoseWeightReason],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
                    LocalStorageKeys[RoutePath.BodyIdealWeight],
                    LocalStorageKeys[RoutePath.Email]
                ]
            }
        });
    }

    if (mw_email_screen_removal === '1') {
        const requiredKeys = [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ];

        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.AnalyzeResponses]: {
                ...finalConfiguration[RoutePath.AnalyzeResponses],
                route: {
                    next: () => RoutePath.PrePaywall,
                },
            },
            [RoutePath.PrePaywall]: {
                ...finalConfiguration[RoutePath.PrePaywall],
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                requiredKeys: requiredKeys
            },
            [RoutePath.Medication]: {
                ...finalConfiguration[RoutePath.Medication],
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                requiredKeys: requiredKeys
            },
            [RoutePath.Checkout]: {
                ...finalConfiguration[RoutePath.Checkout],
                requiredKeys: requiredKeys
            },
        });
    }

    if (mw_how_sema_works === '1' || mw_how_sema_works === '2') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.MedicationIntake]: {
                ...finalConfiguration[RoutePath.MedicationIntake],
                route: {
                    next: () => RoutePath.HowSemaWorks,
                },
            },
            [RoutePath.HowSemaWorks]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: null,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.Consent,
                },
                events: {
                    onEnter: () => 'RxWeightHowSemaWorkViewed',
                    onLeave: () => 'RxWeightHowSemaWorkClicked'
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyGender],
                    LocalStorageKeys[RoutePath.LoseWeightReason],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
                    LocalStorageKeys[RoutePath.BodyIdealWeight],
                ]
            },
        });
    }

    if (mw_trial_picker_screen === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.PrePaywall]: {
                ...finalConfiguration[RoutePath.PrePaywall],
                route: {
                    next: () => RoutePath.TrialPay,
                },
            },
            [RoutePath.TrialPay]: {
                customWrapper: true,
                header: {
                    email: true,
                    logo: false,
                    progress: null,
                },
                route: {
                    next: () => RoutePath.Medication,
                },
                events: {
                    onEnter: () => 'RxWeightTrialPickerScreenViewed',
                    onLeave: () => 'RxWeightTrialPickerScreenClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyGender],
                    LocalStorageKeys[RoutePath.BodyIdealWeight],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
                    LocalStorageKeys[RoutePath.Email],
                ]
            },
        });
    }

    if (mw_medical_director_changes_v2 === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.MedicalCondition]: {
                ...finalConfiguration[RoutePath.MedicalCondition],
                route: {
                    next: () => RoutePath.MedicalConditionPart2,
                },
            },
            [RoutePath.MedicalConditionPart2]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 70,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.MedicationIntake
                },
                events: {
                    onLeave: () => 'RxWeightMedicalConditionPart2Clicked'
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyGender],
                    LocalStorageKeys[RoutePath.LoseWeightReason],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
                    LocalStorageKeys[RoutePath.BodyIdealWeight],
                ]
            },
        });
    }

    if (mw_pre_paywall_on_paywall === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.Email]: {
                ...finalConfiguration[RoutePath.Email],
                route: {
                    next: (data: any) => {
                        if (data?.isUserExists) {
                            handleRedirectToPwa();
                        }


                        if (mw_trial_picker_screen === '1') {
                            return RoutePath.TrialPay;
                        }

                        return RoutePath.Medication;
                    },
                },
            }
        });
    }

    if (mw_medication_upsell === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.Checkout]: {
                ...finalConfiguration[RoutePath.Checkout],
                route: {
                    next: () => RoutePath.MedicationPlan,
                },
            },
            [RoutePath.MedicationPlan]: {
                customWrapper: true,
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 40,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.IdentityVerification,
                },
                events: {},
                requiredKeys: [
                    MultipleLocalStorageKeys[RoutePath.Checkout].firstName,
                    MultipleLocalStorageKeys[RoutePath.Checkout].lastName,
                    LocalStorageKeys[RoutePath.Email]
                ]
            },
            [RoutePath.IdentityVerification]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 80,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.MedicationGuide
                },
                events: {},
                requiredKeys: []
            },
            [RoutePath.MedicationGuide]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 100,
                        label: '',
                    },
                },
                route: {
                    next: () => RoutePath.Congrats
                },
                events: {
                    onEnter: () => 'RxWeightMedicationGuideScreenViewed'
                },
                requiredKeys: []
            },
        });
    }

    return finalConfiguration;
};
