import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';

import { ScrollToTop } from 'helpers/scroll-to-top';

import { AppThemeProvider } from 'context/theme-provider';
import { LastLocationProvider } from 'context/last-location-provider';

import { RouterConfig } from 'routes';

import { PageConfigurationService } from 'services/page-configuration.service';

import { Content } from '../content';

import { AnalyticsKeys } from 'constants/analytics';

import { useUserGeolocation } from 'hooks/use-user-geolocation.hook';

export const pageConfiguration = new PageConfigurationService();

const growthbook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
        console.log('[Experiment]', {
            experimentId: experiment.key,
            variationId: result.key,
        });

        const params = {
            event_category: 'experiment',
            experiment_id: experiment.key,
            variation_id: result.key,
            gb_user_id: localStorage.getItem(AnalyticsKeys.userId),
        };

        window.zaraz.track('experiment_viewed', params);
    },
    onFeatureUsage: (featureKey, result) => {
        const params = {
            event_category: 'feature',
            feature_key: featureKey,
            result: result,
            gb_user_id: localStorage.getItem(AnalyticsKeys.userId),
        };

        window.zaraz.track('feature_viewed', params);

        console.log('[Feature]', { featureKey, result: result.value });
    },
});

function Index() {
    const userId = localStorage.getItem(AnalyticsKeys.userId);
    const { countryCode } = useUserGeolocation();

    useEffect(() => {
        growthbook.loadFeatures();

        if (pageConfiguration) {
            pageConfiguration.event('RxWeightFunnelStartViewed');
        }
    }, []);

    useEffect(() => {
        if (userId && countryCode) {
            growthbook.setAttributes({
                id: userId,
                user_id: userId,
                client_id: userId,
                device_id: navigator?.userAgent,
                country: countryCode,
                device_language: window.navigator.language?.toLowerCase() || window.navigator.userLanguage?.toLowerCase || 'en',
                browser: navigator.userAgent,
                url: window.location.host,
            });
        }
    }, [userId, countryCode]);

    return (
        <BaseContext.Provider value={{
            countryCode,
            pageConfiguration,
        }}>
            <AppThemeProvider>
                <GrowthBookProvider growthbook={growthbook}>
                    <BrowserRouter>
                        <ScrollToTop />
                        <Content>
                            <LastLocationProvider>
                                <RouterConfig />
                            </LastLocationProvider>
                        </Content>
                    </BrowserRouter>
                </GrowthBookProvider>
            </AppThemeProvider>
        </BaseContext.Provider>
    );
}

export default Index;
