import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';

import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

export type PageType = {
    customWrapper?: boolean,
    scrollableContent?: boolean,
    header: {
        email: boolean,
        logo: boolean,
        progress: {
            percents: number | null,
            label: string | null
        } | null
    },
    route: {
        next: (data?: any) => string,
    },
    events: {
        onEnter?: (data?: any) => string,
        onLeave?: (data?: any) => string,
    },
    // @description: string - required & string[] - any of the item required
    requiredKeys?: (string | string[])[],
    footerAtTheBottom?: boolean,
};

export type PageConfigurationType = {
    [key: string]: PageType;
};

export const DEFAULT_PAGES_CONFIGURATION: PageConfigurationType = {
    [RoutePath.Main]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.BodyGender,
        },
        events: {
            onLeave: () => 'RxWeightFunnelStartClicked'
        }
    },
    [RoutePath.Goal]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.BodyGender,
        },
        events: {
            onEnter: () => 'RxWeightWeightLossGoalViewed',
            onLeave: () => 'RxWeightWeightLossGoalClicked'
        }
    },
    [RoutePath.BodyGender]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 8,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.LoseWeight,
        },
        events: {
            onLeave: () => 'RxWeightGenderClicked'
        }
    },
    [RoutePath.LoseWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 16,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.FamilyWeight,
        },
        events: {
            onLeave: () => 'RxWeightLoseWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.FamilyWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 24,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.FamilyWeightTip,
        },
        events: {
            onLeave: () => 'RxWeightFamilyWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.FamilyWeightTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.PastAttempts,
        },
        events: {
            onLeave: () => 'RxWeightFamilyWeightTipClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.PastAttempts]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 32,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.WeightLoss,
        },
        events: {
            onLeave: () => 'RxWeightPastAttemptsClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.WeightLoss]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.LoseWeightReason,
        },
        events: {
            onLeave: () => 'RxWeightWeightLossClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.LoseWeightReason]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 40,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.WeightFactors,
        },
        events: {
            onLeave: () => 'RxWeightLoseWeightReasonClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender]
        ]
    },
    [RoutePath.WeightFactors]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.PrescriptionMedications,
        },
        events: {
            onLeave: () => 'RxWeightWeightFactorsClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason]
        ]
    },
    [RoutePath.PrescriptionMedications]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 48,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.PrescriptionMedicationsTip,
        },
        events: {
            onLeave: () => 'RxWeightPrescriptionMedicationsClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason]
        ]
    },
    [RoutePath.PrescriptionMedicationsTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyHeight,
        },
        events: {
            onLeave: () => 'RxWeightPrescriptionMedicationsTipClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason]
        ]
    },
    [RoutePath.BodyHeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 52,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyWeight,
        },
        events: {
            onLeave: () => 'RxWeightBodyHeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason]
        ]
    },
    [RoutePath.BodyWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 56,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.WeightMetabolicRate,
        },
        events: {
            onLeave: () => 'RxWeightBodyWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
        ]
    },
    [RoutePath.WeightMetabolicRate]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyIdealWeight,
        },
        events: {
            onEnter: () => 'RxWeightMetabolicRateViewed',
            onLeave: () => 'RxWeightMetabolicRateClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs
        ]
    },
    [RoutePath.BodyIdealWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 60,
                label: '',
            },
        },
        route: {
            next: (data) => data.eligibleBMIValue ? RoutePath.BeforeAfterMedication : RoutePath.DeclineUser,
        },
        events: {
            onLeave: () => 'RxWeightBodyIdealWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs
        ]
    },
    [RoutePath.BeforeAfterMedication]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.HealthCondition,
        },
        events: {
            onEnter: () => 'RxWeightBeforeAfterViewed',
            onLeave: () => 'RxWeightBeforeAfterClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.HealthCondition]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 64,
                label: '',
            },
        },
        route: {
            next: (data) => data?.declineUser ? RoutePath.DeclineUser : RoutePath.MedicalCondition,
        },
        events: {
            onLeave: () => 'RxWeightHealthConditionClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.MedicalCondition]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 68,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.MedicationIntake
        },
        events: {
            onLeave: () => 'RxWeightMedicalConditionClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.MedicationIntake]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 72,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.Consent
        },
        events: {
            onLeave: () => 'RxWeightMedicationIntakeClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.Consent]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 76,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.ScientificProof
        },
        events: {
            onLeave: () => 'RxWeightConsentClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.ScientificProof]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null,
        },
        route: {
            next: () => RoutePath.ImportantEvent,
        },
        events: {
            onEnter: () => 'RxWeightYoYoScientificProofViewed',
            onLeave: () => 'RxWeightYoYoScientificProofClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.ImportantEvent]: {
        customWrapper: false,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 77,
                label: '',
            },
        },
        route: {
            next: (data: any) => {
                if (data.key !== 'none') {
                    return RoutePath.ImportantEventDate;
                } else {
                    return RoutePath.AnalyzeResponses;
                }
            }
        },
        events: {
            onLeave: () => 'RxWeightImportantEventClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.ImportantEventDate]: {
        customWrapper: false,
        header: {
            email: false,
            logo: true,
            progress: null,
        },
        route: {
            next: () => RoutePath.AnalyzeResponses,
        },
        events: {},
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.AnalyzeResponses]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.Email,
        },
        events: {
            onLeave: () => 'RxWeightAnalyzeResponsesCompleted'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.Email]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 80,
                label: '',
            },
        },
        route: {
            next: (data) => {
                if (data?.isUserExists) {
                    handleRedirectToPwa();
                }

                return RoutePath.PrePaywall;
            },
        },
        events: {
            onLeave: () => 'RxWeightEmailClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
        ]
    },
    [RoutePath.PrePaywall]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.Medication,
        },
        events: {
            onLeave: () => 'RxWeightPrePaywallClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.Medication]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: null,
                label: '',
            },
        },
        footerAtTheBottom: true,
        route: {
            next: () => RoutePath.Checkout,
        },
        events: {
            onLeave: () => 'RxWeightMedicationClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.Checkout]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.Congrats
        },
        events: {},
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyGender],
            LocalStorageKeys[RoutePath.LoseWeightReason],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt,
            MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs,
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.Congrats]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onEnter: () => 'RxWeightCongratsViewed',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.Welcome]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onLeave: () => 'RxWeightDownloadButtonClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email]
        ]
    },
    [RoutePath.DeclineUser]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyGender,
        },
        events: {
            onLeave: () => 'RxWeightContinueWithoutMedicationsClicked'
        }
    },
    [RoutePath.ShippingAddressEmbedded]: { // Page for opening in WebView
        customWrapper: true,
        scrollableContent: false,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.ShippingAddressEmbedded,
        },
        events: {}
    }
};
