import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '12px',
        borderRadius: '12px',
        background: '#FEF3F3',
        padding: '16px',
        margin: '0 0 32px',

        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',

            '& > p': {
                textAlign: 'left',
                color: '#E64527',
                margin: 'unset'
            },

            '& > p:first-child': {
                fontSize: '18px',
                lineHeight: '26px',
                fontWeight: 600,
            },

            '& > p:last-child': {
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 400,
            }
        }
    },
    '@media (min-width: 768px)': {
        wrapper: {
            maxWidth: '560px',
            margin: '-56px 0 80px',
        },
    }
});
