import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { Typography } from 'components/typography';

import { TypographyVariants } from 'constants/typography-variants';
import { PaymentKeys } from 'constants/payment';

import { useStyles } from './styles';

interface CartProps {
    isDesktop: boolean;
}

export const Cart: FC<CartProps> = ({ isDesktop }) => {
    const {
        cartWrapper,
        headerInfoWrapper,
        cartTitle,
        divider,
        borderTop,
        infoBlockWithImage,
        infoBlock,
        title,
        subtitle,
        blockTitle,
        boldTitle,
        price,
        fullPrice,
        period,
        trial,
        total,
    } = useStyles();

    const { pageValue: plan } = useLocalStorage({
        key: PaymentKeys.planCode,
        defaultValue: {}
    });

    const items = ['svg/checkout/cart/cart-icon.svg', 'webp/checkout/cart/medications.webp'];
    const pageImages = useBackgroundImageService(items);

    const todayTotalPrice = `$${plan.amount / 100}`;
    const subscriptionTotalPrice = `$${plan.subscriptionAmount / 100}`;

    return (
        <div className={cartWrapper}>
            {isDesktop && <div className={headerInfoWrapper}>
                <img src={pageImages[0]} alt='Cart' />
                <Typography variant={TypographyVariants.h2} className={cartTitle}>
                    <Trans i18nKey='checkout-cart-summary' />
                </Typography>
            </div>}

            <div>
                <div className={`${divider} ${isDesktop ? borderTop : ''}`}>
                    <div className={infoBlockWithImage}>
                        <img src={pageImages[1]} alt='Medications' />
                        <div className={infoBlock}>
                            <Typography variant={TypographyVariants.h3} className={title}>
                                {plan.title}
                            </Typography>
                            <Typography variant={TypographyVariants.h3} className={subtitle}>
                                <Trans i18nKey='checkout-cart-details-monthly-membership' />
                            </Typography>
                            <Typography variant={TypographyVariants.h3} className={subtitle}>
                                <Trans i18nKey='checkout-cart-details-medication-cost' />
                            </Typography>
                        </div>
                    </div>
                    <Typography variant={TypographyVariants.h3} className={`${price} ${fullPrice}`}>
                        {subscriptionTotalPrice}<span className={period}>/mo</span>
                    </Typography>
                </div>
                <div className={divider}>
                    <Typography variant={TypographyVariants.h3} className={`${blockTitle} ${boldTitle}`}>
                        <Trans i18nKey='checkout-cart-details-total' />
                    </Typography>
                    <Typography variant={TypographyVariants.h3} className={`${price} ${total}`}>
                        {todayTotalPrice} <span className={trial}>/ <Trans i18nKey='checkout-cart-details-trial' /></span>
                    </Typography>
                </div>
            </div>
        </div>
    );
};
