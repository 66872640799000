import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureValue } from '@growthbook/growthbook-react';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { UserEligibilityKeys } from 'constants/user-eligibility';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { MultiChoiceButton, MultiChoiceType } from 'components/multichoice-button';
import { FloatedButton } from 'components/floated-button';
import { isNextButtonDisabled } from 'helpers/get-multichoice-button-select-options';
import { isUserStatusNeedsReview } from 'helpers/get-user-eligibility';

export const MedicalCondition = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.MedicalCondition];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoicesControl: MultiChoiceType[] = [
        {
            key: 'diabetes',
            value: t('medical-condition-option-diabetes')
        },
        {
            key: 'high-blood-pressure',
            value: t('medical-condition-option-high-blood-pressure')
        },
        {
            key: 'high-cholesterol',
            value: t('medical-condition-option-high-cholesterol')
        },
        {
            key: 'gallbladder-disease',
            value: t('medical-condition-option-gallbladder-disease')
        },
        {
            key: 'liver-disease',
            value: t('medical-condition-option-fatty-liver-disease')
        },
        {
            key: 'malabsorption-syndrome',
            value: t('medical-condition-option-malabsorption-syndrome')
        },
        {
            key: 'none',
            value: t('medical-condition-option-none'),
        }
    ];

    const multiChoicesExp: MultiChoiceType[] = [
        {
            key: 'insulin-dependent-diabetes',
            value: t('medical-condition-option-insulin-dependent-diabetes')
        },
        {
            key: 'non-insulin-dependent-diabetes',
            value: t('medical-condition-option-non-insulin-dependent-diabetes')
        },
        {
            key: 'high-blood-pressure',
            value: t('medical-condition-option-high-blood-pressure')
        },
        {
            key: 'high-cholesterol',
            value: t('medical-condition-option-high-cholesterol')
        },
        {
            key: 'gallbladder-disease',
            value: t('medical-condition-option-gallbladder-disease')
        },
        {
            key: 'liver-disease',
            value: t('medical-condition-option-liver-disease')
        },
        {
            key: 'none',
            value: t('medical-condition-option-none'),
        }
    ];

    const mw_medical_director_changes_v2 = localStorage.getItem(ExperimentsKeys.mw_medical_director_changes_v2) === '1';
    const multiChoices = mw_medical_director_changes_v2 ? multiChoicesExp : multiChoicesControl;

    const handleClick = () => {
        const selectOption = pageValue.map((item: string) => {
            const currentOption = multiChoices.find((option) => option.key === item);

            return (currentOption?.value === 'none' || currentOption?.userAnswer === undefined) ? currentOption?.value : `${currentOption?.value}: ${currentOption?.userAnswer?.trim()}`;
        });

        let userStatus = pageValue[0] === 'none' ? UserEligibilityKeys.userStatus.approved : UserEligibilityKeys.userStatus.needsReview;

        const medicalConditionPart2PageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicalConditionPart2]) as string);
        const medicationIntakePageValue = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.MedicationIntake]) as string);

        if (
            isUserStatusNeedsReview(medicalConditionPart2PageValue, userStatus) ||
            isUserStatusNeedsReview(medicationIntakePageValue, userStatus)
        ) {
            userStatus = UserEligibilityKeys.userStatus.needsReview;
        }

        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('medical-condition-title'),
                value: pageValue[0] === 'none'
                    ?
                    multiChoices
                        .filter(multiChoice => multiChoice.key !== 'none')
                        .map(multiChoice => `"${multiChoice.value}" - NO`)
                    :
                    selectOption
            },
            userStatus: { question: t('medication-user-eligibility-status-question'), value: userStatus }
        });

        pageConfiguration.handleOnPageComplete(pageValue);
    };

    const mw_how_sema_works = useFeatureValue(ExperimentsKeys.mw_how_sema_works, '0');

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mw_how_sema_works, mw_how_sema_works);
    }, [mw_how_sema_works]);

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                {t('medical-condition-title')}
            </Typography>

            {multiChoices.map((v: MultiChoiceType) => (
                <MultiChoiceButton
                    key={v.key}
                    item={v}
                    pageValue={pageValue}
                    setPageValue={setPageValue}
                    textAreaInputLabel={'medical-condition-textarea-label'}
                />
            ))}
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={isNextButtonDisabled(pageValue, multiChoices)} />
            </FloatedButton>
        </>
    );
};
