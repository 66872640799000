import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { UserVerificationKeys } from 'constants/user-verification';
import { LocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { APP_TYPE_APP, APP_TYPE_PWA } from 'constants/app-name';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';
import { detectDeviceType, detectIosDevice } from 'helpers/detect-device-type';

import { MobileContent } from 'pages/welcome/mobile-content';

import { Typography } from 'components/typography';

import ableLogo from 'assets/images/svg/welcome/able-logo.svg';
import vibrantLogo from 'assets/images/svg/welcome/vibrant-logo.svg';
import qrCode from 'assets/images/png/welcome/qr-code.png';

import { useStyles } from './styles';

export const Welcome = () => {
    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const { t } = useTranslation();

    const {
        logosWrapper,
        welcomeDesktopInfoWrapper,
        welcomeDesktopTitle,
        welcomeDesktopSubtitle,
        qrCodeImage,
        listWrapper,
        list,
        listTitle,
        listItem,
    } = useStyles();

    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]) as string;
    const isUserExists = localStorage.getItem(UserVerificationKeys.isUserExists) === 'true';
    const pwaNativeSplitVariationExp = true;
    const userApp = pwaNativeSplitVariationExp ? APP_TYPE_PWA : APP_TYPE_APP;

    const handleClick = () => {
        const linkDownload = `https://install.ableapp.com/gdEx?deep_link_value=ableapp://email_login?email=${encodeURIComponent(email)}`;

        window.open(linkDownload, '_blank');

        pageConfiguration.handleOnPageComplete({ user_app: userApp });
    };

    useEffect(() => {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://banner.urlgeni.us/smart_banners/75f8f156e39bdf64.js?url=' + encodeURI(window.location.toString());
        head.appendChild(script);
    }, []);

    const deviceType = detectDeviceType();
    const isDesktop = deviceType !== 'mobile';
    const isIosDevice = detectIosDevice();

    const appStoreLinkComponent = (
        <a href='https://apps.apple.com/us/app/vibrantlife-connect/id6451144527' target='_blank'
           rel='noreferrer nofollow'>
            AppStore
        </a>
    );
    const googlePlayLinkComponent = (
        <a href='https://play.google.com/store/apps/details?id=com.able.user' target='_blank' rel='noreferrer nofollow'>
            Google Play
        </a>
    );

    const ableAppName = t('welcome-instructions-title-personalized');

    return (
        <>
            {(isIosDevice || isDesktop) && <div className={logosWrapper}>
                <img src={ableLogo} alt='Able Logo' />
                <img src={vibrantLogo} alt='Vibrant Logo' />
            </div>}

            {isDesktop ? <>
                <Typography className={welcomeDesktopTitle} variant={TypographyVariants.h1} typographyNext>
                    {!isUserExists && t('welcome-title')}
                    {isUserExists && t('welcome-title-if-user-exists')}
                </Typography>
                <Typography className={welcomeDesktopSubtitle} variant={TypographyVariants.h2}>
                    {t('welcome-desktop-info')}
                </Typography>

                <div className={welcomeDesktopInfoWrapper}>
                    <div className={listWrapper}>
                        <Typography className={listTitle}
                                    variant={TypographyVariants.h2}>
                            {!isUserExists && t('welcome-instructions-title', { ableAppName })}
                            {isUserExists && t('welcome-subtitle-if-user-exists')}
                        </Typography>
                        <ol className={list}>
                            <Typography className={listItem} variant={TypographyVariants.h2}>
                                <li>
                                    {t('welcome-list-text-desktop-scan-qr')}
                                </li>
                            </Typography>
                            <Typography className={listItem} variant={TypographyVariants.h2}>
                                <li>
                                    <Trans i18nKey='welcome-list-text-desktop-store-redirect'
                                           components={[appStoreLinkComponent, googlePlayLinkComponent]} />
                                </li>
                            </Typography>
                            <Typography className={listItem} variant={TypographyVariants.h2}>
                                <li>
                                    {t('welcome-list-text-desktop-download-app')}
                                </li>
                            </Typography>
                            <Typography className={listItem} variant={TypographyVariants.h2}>
                                <li>
                                    {!isUserExists && t('welcome-list-text-desktop-open-app', { email })}
                                    {isUserExists && t('welcome-list-text-desktop-open-app-if-user-exists', { email })}
                                </li>
                            </Typography>

                            {!isUserExists &&
                                <Typography className={listItem} variant={TypographyVariants.h2}>
                                    <li>
                                        {t('welcome-list-text-desktop-create-password')}
                                    </li>
                                </Typography>
                            }
                        </ol>
                    </div>

                    <img className={qrCodeImage} src={qrCode} alt='customer' />
                </div>
            </> : <MobileContent isIosDevice={isIosDevice} isUserExists={isUserExists} email={email}
                                 handleClick={handleClick} />}
        </>
    );
};
