import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';
import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { PaymentKeys } from 'constants/payment';

import { Typography } from 'components/typography';
import { TypographyVariants } from 'constants/typography-variants';
import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { useStyles } from './styles';

export const Program = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();

    const {
        programWrapper,
        programTitle,
        medicationWrapper,
        programItemBlock,
        programItem,
        circle,
        medicationOptions,
        priceBlock,
        nextButtonStyles
    } = useStyles();

    const { pageValue: plan } = useLocalStorage({
        key: PaymentKeys.planCode,
        defaultValue: {}
    });

    const items = ['webp/medication/medications-pack.webp', 'svg/medication/calendar.svg', 'svg/medication/refund.svg', 'svg/medication/medication-bottle.svg', 'svg/medication/delivery.svg'];
    const pageImages = useBackgroundImageService(items);

    const isDesktop = useDesktopMediaWatcher();

    const todayTotalPrice = `$${plan.amount / 100}`;
    const subscriptionPrice = `$${plan.subscriptionAmount / 100}`;

    return (
        <div className={programWrapper}>
            <Typography variant={TypographyVariants.h1} className={programTitle}>
                {t('medication-program-title')}
            </Typography>

            <div className={medicationWrapper}>
                <img src={pageImages[0]} alt='Medications Pack' />

                <div className={programItemBlock}>
                    <div className={programItem}>
                        <div className={circle}>1</div>
                        <Typography variant={TypographyVariants.h3}>
                            {t('medication-program-option-1')}
                        </Typography>
                    </div>
                    <div className={programItem}>
                        <div className={circle}>2</div>
                        <Typography variant={TypographyVariants.h3}>
                            {t('medication-program-option-2')}
                        </Typography>
                    </div>
                    <div className={programItem}>
                        <div className={circle}>3</div>
                        <Typography variant={TypographyVariants.h3}>
                            {t('medication-program-option-3')}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className={medicationOptions}>
                <div className={programItem}>
                    <img src={pageImages[1]} alt="Calendar" />
                    <Typography variant={TypographyVariants.h3}>
                        {t('medication-program-option-4')}
                    </Typography>
                </div>
                <div className={programItem}>
                    <img src={pageImages[2]} alt="Refund" />
                    <Typography variant={TypographyVariants.h3}>
                        {t('medication-program-option-5')}
                    </Typography>
                </div>
                <div className={programItem}>
                    <img src={pageImages[3]} alt="Medication Cost" />
                    <Typography variant={TypographyVariants.h3}>
                        {t('medication-program-option-6')}
                    </Typography>
                </div>
                <div className={programItem}>
                    <img src={pageImages[4]} alt="Delivery" />
                    <Typography variant={TypographyVariants.h3}>
                        {t('medication-program-option-7')}
                    </Typography>
                </div>
            </div>

            <div className={priceBlock}>
                <Typography variant={TypographyVariants.h1} typographyNext>
                    {todayTotalPrice} / <Trans i18nKey='medication-program-trial' />
                </Typography>

                <Typography variant={TypographyVariants.h2}>
                    <Trans i18nKey='medication-program-subscription' values={{subscriptionPrice}} components={[<strong></strong>]} />
                </Typography>

            </div>

            <FloatedButton floated={!isDesktop} withGradient>
                <NextButton
                    className={`${nextButtonStyles}`}
                    onClick={onClick}
                    typographyText={t('medication-program-button-next')} />
            </FloatedButton>

        </div>
    );
};
