import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { BaseContext } from 'context/base.context';

import { PrimerProvider } from 'api/primer.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { getSignUpData } from 'helpers/sign-up-data';
import { getPaymentGeneralErrorKey } from 'helpers/payment';

import { LocalStorageKeys } from 'constants/local-storage';
import { CUSTOMER_ID, PaymentKeys } from 'constants/payment';
import { APP_TYPE_APP, APP_TYPE_PWA } from 'constants/app-name';
import { UserGeolocationKeys } from 'constants/user-geolocation';

export const usePrimerIOPayment = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const [error, setError] = useState('');
    const [createSubscriptionLoadingRequest, setCreateSubscriptionLoadingRequest] = useState(false);

    const { pageValue: email } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email],
        defaultValue: '',
    });

    const { pageValue: plan } = useLocalStorage({
        key: PaymentKeys.planCode,
        defaultValue: {}
    });

    const pwaNativeSplitVariationExp = true;

    const PaymentSignUp = (signUpData: any) => {
        return PrimerProvider.subscriptionCreate({ ...signUpData })
            .then((data) => {
                localStorage.setItem(CUSTOMER_ID, data.customer.id);

                pageConfiguration.event('RxWeightSubscriptionCheckoutPaymentAttempt', {
                    PaymentStatus: 'Success',
                    paymentType: signUpData.type,
                    value: plan?.key,
                    coupon: signUpData?.coupon,
                });

                pageConfiguration.event('RxWeightSubscriptionPurchased', {
                    paymentType: signUpData.type,
                    PlanName: plan?.key,
                    coupon: signUpData?.coupon,
                    user_id: data.customer.id,
                    email: signUpData.customer.email,
                    first_name: signUpData.customer.firstName,
                    last_name: signUpData.customer.lastName,
                    trial_price: signUpData.amount,
                    currency: signUpData.currencyCode,
                    billingPlatform: signUpData.type,
                    appName: signUpData.appName,
                    subdomain: signUpData.subdomain,
                    user_app: pwaNativeSplitVariationExp ? APP_TYPE_PWA : APP_TYPE_APP
                });

                if (window.zaraz) {
                    window.zaraz.track('Purchase', {
                        external_id: data.customer.id,
                        email: signUpData.customer.email,
                        first_name: signUpData.customer.firstName,
                        last_name: signUpData.customer.lastName,
                        gender: signUpData.gender,
                        country: signUpData.country.toLowerCase(),
                        city: localStorage.getItem(UserGeolocationKeys.city),
                        value: signUpData.amount,
                        currency: signUpData.currencyCode,
                    });
                }

                const affid = localStorage.getItem('affid');
                const affise_clickid = localStorage.getItem('affise_clickid');

                if (affid && affise_clickid && window.zaraz) {
                    window.zaraz.track('affise_postback', {
                        endpoint: `${process.env.REACT_APP_AFFISE_API_URL}/postback?goal=trial&status=2&clickid=${affise_clickid}&affid=${affid}`,
                    });
                }
            })
            .then(() => {
                return pageConfiguration.handleOnPageComplete();
            })
            .catch((e: any) => {
                console.log(e);

                pageConfiguration?.event('RxWeightSubscriptionCheckoutPaymentAttempt', {
                    PaymentStatus: 'Error',
                    paymentType: signUpData.type,
                    email: signUpData.customer.email,
                    PaymentError: e,
                    value: plan?.key,
                    appName: signUpData.appName,
                    subdomain: signUpData.subdomain,
                    coupon: signUpData?.coupon,
                });

                setError(t(getPaymentGeneralErrorKey(e?.error)));

                return Promise.reject(e?.error);
            })
            .finally(() => {
                setCreateSubscriptionLoadingRequest(false);
            });
    };

    const handleSubmit = (paymentMethodTokenData: { userId: string, id: string, orderId: string, paymentMethodData: any }) => {
        setCreateSubscriptionLoadingRequest(true);

        const data = getSignUpData(
            {
                customerId: paymentMethodTokenData.userId,
                primerTransactionId: paymentMethodTokenData.id,
                primerOrderId: paymentMethodTokenData.orderId,
                primerPaymentMethodData: paymentMethodTokenData.paymentMethodData,
                planCode: plan.key,
                amount: plan.amount,
                subscriptionAmount: plan.subscriptionAmount,
                trialPeriodDays: plan.trialPeriod,
                chargePeriod: plan.subscriptionPeriod,
                chargePeriodUnit: plan.chargePeriodUnit,
                currencyCode: plan.currencyCode,
            }
        );

        return PaymentSignUp(data);
    };

    const handleCancelAppleGooglePay = () => {
        console.log('RxWeightAppleGooglePayCloseClicked');
        pageConfiguration?.event('RxWeightAppleGooglePayCloseClicked');
    };

    const onBeforePaymentCreate = (payment_method: string) => {
        pageConfiguration.event('RxWeightSubscriptionCheckoutPaymentMethodClicked', { payment_method });
    };

    const onCheckoutFail = (error: string) => {
        setError(error);
        pageConfiguration.event('RxWeightSubscriptionCheckoutPayError');
    };

    return {
        plan,
        email,
        error,
        createSubscriptionLoadingRequest,
        onCheckoutFail,
        onBeforePaymentCreate,
        onCheckoutComplete: handleSubmit,
        handleCancelAppleGooglePay
    };
};
