import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGrowthBook } from '@growthbook/growthbook-react';

import { RoutePath } from 'routes/route-path.constant';

import { useUTMSearch } from 'hooks/use-encharge-search.hook';

import { ExperimentsKeys } from 'constants/experiments';
import { AnalyticsKeys } from 'constants/analytics';

export const MainPage = () => {
    const navigate = useNavigate();
    const UTMSearch = useUTMSearch();
    const growthbook = useGrowthBook();

    const userId = localStorage.getItem(AnalyticsKeys.userId) as string;

    // Temporarily disable the experiment
    const disableExperiment = true;

    useEffect(() => {
        if (disableExperiment) {
            navigate(`../${RoutePath.BodyGender}${UTMSearch}`);
            return;
        }

        if (userId && growthbook) {
            growthbook.setAttributeOverrides({
                id: userId,
            });

            const { value } = growthbook.run({
                key: ExperimentsKeys.mw_first_screen,
                variations: ["0", "1"],
            });

            if (value === '1') {
                localStorage.setItem(ExperimentsKeys.mw_first_screen, '1');
                navigate(`../${RoutePath.Goal}${UTMSearch}`);
            } else {
                localStorage.setItem(ExperimentsKeys.mw_first_screen, '0');
                navigate(`../${RoutePath.BodyGender}${UTMSearch}`);
            }
        }

    }, [userId, growthbook]);

    return null;
};
