import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    pageWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 16px 32px',
    },
    title: {
        fontSize: '20px',
        lineHeight: '28px',
        maxWidth: '560px',
    },
    listWrapper: {
        margin: '32px 0',
        maxWidth: '560px',

        '& p': {
            fontSize: '14px',
            lineHeight: '18px',
            textAlign: 'left',
            margin: '0 0 12px',
        },

        '& > p': {
            margin: '0 0 16px',
        },

        '& > p:last-child': {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 400,
            textAlign: 'left',
            color: '#999999',
            margin: '16px 0 0'
        },

        '& > ul': {
            listStyle: 'disc',
            listStylePosition: 'outside',
            marginLeft: '16px',
            maxWidth: '358px',

            '& > li:last-child > p': {
                margin: '0 0 16px',
            },

            '& > li::marker': {
                fontSize: '10px',
            }
        }
    },
    multiChoiceWrapper: {

        '& button': {
            height: '100%',
            minHeight: 'unset',
            gap: '8px',
            padding: 0,
            background: 'none',
            border: 'none',
            color: '#010101',
            '-webkit-tap-highlight-color': 'transparent',

            '&:hover': {
                background: 'none!important'
            },

            '& > span': {
                fontSize: '14px',
                lineHeight: '18px',
            }
        },

        '& label > span[class^=Checkbox]': {
            width: '20px',
            height: '20px',
            top: '-10px',
            border: '1px solid #C5C5D1',
            cursor: 'pointer',

            '&::after': {
                left: '6px',
                top: '1px'
            }
        },
    },
    link: {
        textDecoration: 'none',
        color: '#1375D6'
    },
    confirmOrderButton: {
        ...nextButton.nextButton,
        height: '100%',
        padding: '16px',
        margin: '0 0 16px',

        '&:active': {
            ...nextButton.nextButtonFocus,
        }
    },
    skipButton: {
        ...nextButton.nextButton,
        height: '100%',
        background: 'none',
        color: '#EB731A',
        padding: 0,
        margin: 0,
    },
    '@media (min-width: 768px)': {
        title: {
            fontSize: '28px',
        },
        listWrapper: {
            margin: '40px 0 80px',

            '& > p': {
                margin: '0 0 24px',
            },

            '& > ul': {
                marginLeft: '16px',

                '& > li:last-child > p': {
                    margin: '0 0 24px',
                },
            }
        },
        confirmOrderButton: {
            minHeight: '56px',
            margin: '0 0 24px',

            '& span': {
                lineHeight: '24px'
            }
        },
        skipButton: {
            margin: 0,

            '& span': {
                lineHeight: '24px'
            }
        },
    },
    '@media (hover: hover)': {
        confirmOrderButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            },
        }
    }
}));
