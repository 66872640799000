import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'helpers';

import { GoogleAddressValidation } from 'api/google-address-validation.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { US_KEY } from 'hooks/use-user-geolocation.hook';

import { RoutePath } from 'routes/route-path.constant';
import { pageConfiguration } from 'containers/index';

import { MultipleLocalStorageKeys } from 'constants/local-storage';
import { TypographyVariants } from 'constants/typography-variants';
import { US_STATES } from 'constants/states';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { GoogleStreetAddress, ShippingAddress } from 'components/shipping-address';
import { CorrectionModalWindow } from 'components/shipping-address/correction-modal-window';
import { validateResponse } from 'components/shipping-address/validation';

import { ShippingStates } from 'pages/checkout/shipping-address-form/shipping-states';

import { useStyles } from './styles';

interface ShippingAddressFormProps {
    handleSubmit: (address: string) => void;
}

export const ShippingAddressForm: FC<ShippingAddressFormProps> = ({ handleSubmit }) => {
    const { t } = useTranslation();

    const { title} = useStyles();

    const states = US_STATES.map(s => ({ value: s.code, label: s.name }));
    const statesNames = US_STATES.map(s => s.name).join(', ');

    const key = MultipleLocalStorageKeys[RoutePath.Checkout];

    const [validationListener, setValidationListener] = useState(false);
    const [stateNeedValidation, setStateNeedValidation] = useState(false);

    const [addressCorrected, setAddressCorrected] = useState(false);

    const [streetAddressInvalid, setStreetAddressInvalid] = useState<null | boolean>(null);
    const [address2Invalid, setAddress2Invalid] = useState<null | boolean>(null);
    const [cityInvalid, setCityInvalid] = useState<null | boolean>(null);
    const [stateInvalid, setStateInvalid] = useState<null | boolean>(null);
    const [zipCodeInvalid, setZipCodeInvalid] = useState<null | boolean>(null);

    const { pageValue: streetAddress, setPageValue: setStreetAddress } = useLocalStorage({
        key: key.streetAddress as string, defaultValue: ''
    });
    const { pageValue: address2, setPageValue: setAddress2 } = useLocalStorage({
        key: key.address2 as string,
        defaultValue: '',
    });
    const { pageValue: city, setPageValue: setCity } = useLocalStorage({
        key: key.city as string,
        defaultValue: ''
    });
    const { pageValue: state, setPageValue: setState } = useLocalStorage({
        key: key.state as string,
        defaultValue: '',
    });
    const { pageValue: zipCode, setPageValue: setZipCode } = useLocalStorage({
        key: key.zipCode as string,
        defaultValue: '',
    });

    const { setPageValue: setFullAddress } = useLocalStorage({ key: key.fullAddress as string, defaultValue: '' });
    const {
        pageValue: googleValidatorFormattedAddress,
        setPageValue: setGoogleValidatorFormattedAddress
    } = useLocalStorage({ key: key.googleValidatorFormattedAddress as string, defaultValue: '' });

    const onAddress2Change = (e: any) => {
        setAddress2(e.target.value);
        setAddress2Invalid(false);
    };

    const onCityChange = (e: any) => {
        setCity(e.target.value);
        setCityInvalid(false);
    };

    const onStateChange = (state: string) => {
        setState(state);
        setStateInvalid(false);
    };

    const onZipCodeChange = (e: any) => {
        setZipCode(e.target.value);
        setZipCodeInvalid(false);
    };

    const toggleValidation = () => {
        setValidationListener(!validationListener);
    };

    const handleSelectStreetAddress = ({ result, name }: GoogleStreetAddress) => {

        if (validationListener && !result?.address_components?.length) {
            return;
        }

        setStreetAddress(name);

        setFullAddress(result.formatted_address);

        setStreetAddressInvalid(false);
        setAddress2Invalid(false);
        setCityInvalid(false);
        setStateInvalid(false);
        setZipCodeInvalid(false);

        // street_number               -> address street number
        // route                       -> the street name
        // locality                    -> the city/town
        // administrative_area_level_3 -> the city/town
        // administrative_area_level_1 -> the state
        // postal_code                 -> zip/postal code
        result?.address_components.forEach((address) => {
            if (address.types.includes('locality') || address.types.includes('administrative_area_level_3')) {
                setCity(address.long_name);
            }
            if (address.types.includes('administrative_area_level_1')) {
                setState(address.short_name);
            }
            if (address.types.includes('postal_code')) {
                setZipCode(address.long_name);
            }
        });

        toggleValidation();
    };

    const handleComponentSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const emptyStreetAddress = isEmpty(streetAddress);
        const emptyCity = isEmpty(city);
        const emptyState = isEmpty(state);
        const emptyZipCode = isEmpty(zipCode);
        const stateIsAllowed = states.some(s => s.value === state);

        if (emptyStreetAddress || emptyCity || emptyState || emptyZipCode || !stateIsAllowed) {
            if (emptyStreetAddress) {
                setStreetAddressInvalid(true);
            }
            if (emptyCity) {
                setCityInvalid(true);
            }
            if (emptyState || !stateIsAllowed) {
                setStateInvalid(true);
            }
            if (emptyZipCode) {
                setZipCodeInvalid(true);
            }
            setStateNeedValidation(true);
            toggleValidation();
            return;
        }

        const addressLines = `${streetAddress}, ${address2} ${city}, ${state}, ${zipCode}`;

        const response = await GoogleAddressValidation.validate({ addressLines, regionCode: US_KEY });

        const validated = validateResponse(response);

        console.log('----- AddressLines', addressLines);
        console.log('----- AddressValidation Response', response);
        console.log('----- Validated Response', validated);

        setGoogleValidatorFormattedAddress(response.address.formattedAddress);

        setStreetAddressInvalid(!validated.streetAddress.valid);
        if (validated.streetAddress.changed) {
            setStreetAddress(validated.streetAddress.text);
        } else if (!isEmpty(validated.streetAddress.value) && streetAddress !== validated.streetAddress.value) {
            setStreetAddress(validated.streetAddress.value);
            validated.streetAddress.changed = true;
        }

        setAddress2Invalid(!validated.address2.valid);
        if (validated.address2.changed) {
            setAddress2(validated.address2.text);
        } else if (!isEmpty(validated.address2.value) && address2 !== validated.address2.value) {
            setAddress2(validated.address2.value);
            validated.address2.changed = true;
        }

        setCityInvalid(!validated.city.valid);
        if (validated.city.changed) {
            setCity(validated.city.text);
        } else if (!isEmpty(validated.city.value) && city !== validated.city.value) {
            setCity(validated.city.value);
            validated.city.changed = true;
        }

        setStateInvalid(!validated.state.valid);
        if (validated.state.changed) {
            setState(validated.state.text);
        } else if (!isEmpty(validated.state.value) && state !== validated.state.value) {
            setState(validated.state.value);
            validated.state.changed = true;
        }

        setZipCodeInvalid(!validated.zipCode.valid);
        if (validated.zipCode.changed) {
            setZipCode(validated.zipCode.text);
        } else if (!isEmpty(validated.zipCode.value) && zipCode.toString() !== validated.zipCode.value) {
            setZipCode(validated.zipCode.value);
            validated.zipCode.changed = true;
        }

        // @ts-ignore
        if (Object.keys(validated).map((k: string) => validated[k]).some(c => !c.valid)) {
            e.preventDefault();
            e.stopPropagation();
            pageConfiguration.event('RxWeightAddressValidationFailedClicked', {
                enteredAddress: addressLines,
                formattedAddress: response.address.formattedAddress
            });
            return;
        }

        // @ts-ignore
        if (Object.keys(validated).map(k => validated[k]).some(c => c.changed)) {
            setAddressCorrected(true);
            e.preventDefault();
            e.stopPropagation();
            pageConfiguration.event('RxWeightAddressValidationFailedClicked', {
                enteredAddress: addressLines,
                formattedAddress: response.address.formattedAddress
            });
            return;
        }

        handleSubmit(addressLines);
        pageConfiguration.event('RxWeightContinueToPaymentClicked');
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title}>
                {t('checkout-shipping-address-form-title')}
            </Typography>

            {addressCorrected &&
                <CorrectionModalWindow
                    address={googleValidatorFormattedAddress}
                    handleClick={() => setAddressCorrected(false)} />
            }

            <ShippingAddress streetAddress={streetAddress}
                             handleStreetAddressChange={(address) => { setStreetAddress(address) }}
                             handleSelectStreetAddress={handleSelectStreetAddress}
                             streetAddressInvalid={streetAddressInvalid}
                             address2={address2}
                             onAddress2Change={onAddress2Change}
                             address2Invalid={address2Invalid}
                             city={city}
                             onCityChange={onCityChange}
                             cityInvalid={cityInvalid}
                             states={states}
                             state={state}
                             onStateChange={onStateChange}
                             stateInvalid={stateInvalid}
                             stateNeedValidation={stateNeedValidation}
                             zipCode={zipCode}
                             onZipCodeChange={onZipCodeChange}
                             zipCodeInvalid={zipCodeInvalid}
                             validationListener={validationListener}
            />

            <ShippingStates statesNames={statesNames} />

            <NextButton onClick={handleComponentSubmit}
                        disabled={validationListener && (isEmpty(streetAddress) || isEmpty(city) || isEmpty(state) || isEmpty(zipCode))}
                        typographyText={t('checkout-shipping-address-form-button-next')} />
        </>
    );
};

export const getShippingAddressDetails = () => {
    const key = MultipleLocalStorageKeys[RoutePath.Checkout];

    const streetAddress = localStorage.getItem(key.streetAddress as string) || '';
    const address2 = localStorage.getItem(key.address2 as string) || '';
    const city = localStorage.getItem(key.city as string) || '';
    const state = localStorage.getItem(key.state as string) || '';
    const zipCode = localStorage.getItem(key.zipCode as string) || '';
    const fullAddress = localStorage.getItem(key.fullAddress as string) || '';

    return { streetAddress, address2, city, state, zipCode, fullAddress };
};

export const isValidShippingAddressForm = () => {
    const { streetAddress, city, state, zipCode } = getShippingAddressDetails();

    return !isEmpty(streetAddress) && !isEmpty(city) && !isEmpty(state) && !isEmpty(zipCode);
};
