import { createUseStyles } from 'react-jss';
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    accountFormWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxWidth: '560px',

        '& > h3:last-of-type': {
            textAlign: 'left'
        }
    },
    title: {
        margin: 'unset',
        lineHeight: '24px',
        textAlign: 'left',
    },
    multipleFieldsWrapper: {
        display: 'flex',
        width: '100%',
        gap: '16px',
        maxWidth: '560px',
        margin: '0 auto',
        '& div': {
            width: '100%',
        }
    },
    continueToCheckoutButton: {
        ...nextButton.nextButton,
        margin: '16px 0 20px',

        '&:active': {
            ...nextButton.nextButtonFocus,
        },

        '&:disabled': {
            ...nextButton.nextButtonDisabled,
        }
    },
    '@media (min-width: 768px)' : {
        accountFormWrapper: {

            '& > h3:last-of-type': {
                fontSize: '18px',
                lineHeight: '26px',
                margin: '8px 0 0'
            }
        },
        title: {
            margin: '0 0 8px'
        }
    },
    '@media (hover: hover)': {
        nextButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            }
        }
    }
}));
