import React from 'react';

import { RoutePath } from 'routes/route-path.constant';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';
import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { ListItem } from 'pages/how-sema-works/content-v1/list-item';

import { useStyles } from './styles';

export const ContentV1 = () => {
    const { contentWrapper } = useStyles();

    const isDesktop = useDesktopMediaWatcher();

    const pageImages = useBackgroundImageService([
        'webp/how-sema-works/man-body.webp',
        'webp/how-sema-works/man-body-mobile.webp',
        'webp/how-sema-works/woman-body.webp',
        'webp/how-sema-works/woman-body-mobile.webp',
    ]);

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);

    const getPageImageIndex = () => {
        if (userGender === 'female') {
            return isDesktop ? 2 : 3;
        } else {
            return isDesktop ? 0 : 1;
        }
    };

    return (
        <div className={contentWrapper}>
            <img src={pageImages[getPageImageIndex()]} alt='Human Body' />

            <div>
                <ListItem
                    textKeys={[
                        'how-sema-works-list-item-1',
                        'how-sema-works-list-item-2',
                    ]}
                />
                <ListItem
                    textKeys={[
                        'how-sema-works-list-item-3',
                        'how-sema-works-list-item-4',
                    ]}
                />
                <ListItem
                    textKeys={[
                        'how-sema-works-list-item-5',
                        'how-sema-works-list-item-6',
                        'how-sema-works-list-item-7',
                    ]}
                />
                <ListItem
                    textKeys={['how-sema-works-list-item-8']}
                />
            </div>
        </div>
    );
};
