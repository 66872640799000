import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

interface RadioButtonProps {
    badgeTitle: string;
    badgeImgSrc: string;
    imageSrc: string;
    title: string;
    description: string;
    checked: boolean;
    onClick: () => void;
    medicationPlanPrice: number;
}

export const RadioButton: FC<RadioButtonProps> = ({
                                                      badgeTitle,
                                                      badgeImgSrc,
                                                      imageSrc,
                                                      title,
                                                      description,
                                                      checked,
                                                      onClick,
                                                      medicationPlanPrice }) => {
    const {
        badge,
        radioButton,
        priceBlock,
        selected,
        radioSelector,
    } = useStyles();

    return (
            <div className={`${radioButton} ${checked ? selected : ''}`} onClick={onClick}>
                <div>
                    <div className={badge}>
                        <img src={badgeImgSrc} alt='Star' />
                        <Typography variant={TypographyVariants.h3}>
                            {badgeTitle}
                        </Typography>
                    </div>
                    <img src={imageSrc} alt='Medication' />
                </div>
                <div>
                    <Typography variant={TypographyVariants.h2}>
                        {title}
                    </Typography>
                    <div className={priceBlock}>
                        <Typography variant={TypographyVariants.h3}>
                            <Trans i18nKey='medication-program-radio-button-subscription-price'
                                   values={{ medicationPlanPrice }}/>
                        </Typography>
                    </div>
                </div>
                <div>
                    <Typography variant={TypographyVariants.h3}>
                        {description}
                    </Typography>
                    <div className={radioSelector}>
                        <input type='radio' checked={checked} readOnly />
                        <span></span>
                    </div>
                </div>
            </div>
    );
};
