import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

import { RoutePath } from 'routes/route-path.constant';
import { BaseContext } from 'context/base.context';

import { useEnchargeSearch } from 'hooks/use-encharge-search.hook';

import { ThinProgressBar } from 'components/progress-bar-thin';

import { ExperimentsKeys } from 'constants/experiments';

import { LogoHeader } from '../header/logo';
import { EmailHeader } from '../header/email';
import { Footer } from '../footer';

import { useStyles } from './styles';

export const Content = ({ children }: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { routePath, UTMSearch, isRequiredKeysExists } = useEnchargeSearch();

    const { pageConfiguration } = useContext(BaseContext);

    const page = pageConfiguration
        .setNavigate(navigate)
        .setConfigurationByLocation(location)
        .getPageConfiguration();

    let backButtonNotAvailableLocations = [
        RoutePath.Main,
        RoutePath.BodyGender,
        RoutePath.MedicationPlan,
        RoutePath.IdentityVerification,
        RoutePath.MedicationGuide,
        RoutePath.Congrats,
        RoutePath.Welcome
    ];

    if (location.pathname === RoutePath.BodyGender) {
        const mw_first_screen = localStorage.getItem(ExperimentsKeys.mw_first_screen) === '1';

        if (mw_first_screen) {
            backButtonNotAvailableLocations = backButtonNotAvailableLocations.filter(path => path !== RoutePath.BodyGender);
        }
    }

    const [backButtonAvailable, setBackButtonAvailable] = useState(!backButtonNotAvailableLocations.includes(location.pathname));

    useEffect(() => {
        pageConfiguration.handleOnPageEnter();
    }, [page]);

    useEffect(() => {
        if (routePath) {
            navigate(`..${routePath}${UTMSearch}`);
        }
    }, [routePath]);

    const { mainContent } = useStyles({
        scrollableContent: page.scrollableContent,
    });

    useEffect(() => {
        setBackButtonAvailable(!backButtonNotAvailableLocations.includes(location.pathname));
    }, [location.pathname]);

    const footerVisible = [RoutePath.Medication, RoutePath.Checkout].includes(location.pathname);

    if (routePath !== null && routePath !== location.pathname) {
        return null;
    }

    return (
        <>
            {(page?.requiredKeys && !isRequiredKeysExists(page?.requiredKeys))
                ?
                <Navigate to={`${RoutePath.Main}${UTMSearch}`} />
                :
                <>
                    {page.header.email ? <EmailHeader backButtonAvailable={backButtonAvailable} /> : null}
                    {page.header.logo ? <LogoHeader backButtonAvailable={backButtonAvailable} /> : null}
                    {
                        <>
                            {page.header.progress && <ThinProgressBar progress={page.header.progress} />}
                            {page.customWrapper ? children : (<div className={mainContent}>
                                {children}
                            </div>)}
                        </>
                    }
                    {footerVisible && <Footer />}
                </>
            }
        </>
    );
};
