import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    title: {
        marginBottom: '16px'
    },
    subtitle: {
        margin: '0 0 16px',
    },
    listWrapper: {
        marginBottom: '32px'
    },
    listItem: {
        display: 'flex',
        marginBottom: '16px',

        '&:last-child': {
            marginBottom: '12px',
        },

        '& img': {
            alignSelf: 'flex-start'
        }
    },
    listItemText: {
        textAlign: 'left',
        marginLeft: '12px',
        fontSize: '18px',
        lineHeight: '26px',
    },
    beginVerificationButton: {
        ...nextButton.nextButton,
        height: '100%',
        padding: '16px',
        margin: '0 0 16px',

        '&:active': {
            ...nextButton.nextButtonFocus,
        }
    },
    verifyLaterButton: {
        ...nextButton.nextButton,
        height: '100%',
        background: 'none',
        color: '#EB731A',
        padding: 0,
        margin: 0,
    },
    '@media (min-width: 768px)' : {
        title: {
            marginBottom: '32px'
        },
        subtitle: {
            margin: '0 0 32px'
        },
        listWrapper: {
            marginBottom: '80px'
        },
        beginVerificationButton: {
            minHeight: '56px',
            margin: '0 0 16px',
        },
        verifyLaterButton: {
            margin: 0,

            '& span': {
                lineHeight: '24px'
            }
        },
    },
    '@media (hover: hover)': {
        beginVerificationButton: {
            "&:hover": {
                ...nextButton.nextButtonHovered,
            },
        }
    }
}));
