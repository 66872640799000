import { createUseStyles } from 'react-jss'
import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    programWrapper: {
        background: '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 16px',
        marginTop: '24px',
    },
    programTitle: {
        lineHeight: '24px',
        textAlign: 'left',
        margin: '12px 0'
    },
    medicationWrapper: {
        borderTop: '1px solid #C5C5D1',
        borderBottom: '1px solid #C5C5D1',
        padding: '24px 0',

        '& img': {
            width: '100%',
            height: '168px',
            borderRadius: '8px',
            objectFit: 'cover'
        }
    },
    programItemBlock: {
        marginTop: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
    },
    programItem: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '12px',

        '& h3': {
            textAlign: 'inherit',
            fontSize: '16px',
            lineHeight: '24px',
        }
    },
    circle: {
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        background: '#1375D6',
        color: '#FFFFFF',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'SF Pro Text',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        justifyContent: 'center',
        flexShrink: 0,
    },
    medicationOptions: {
        margin: '24px 0 12px 0',

        '& $programItem': {
            gap: '8px',
            alignItems: 'center',
            textAlign: 'left',
            marginBottom: '12px',

            '& img': {
                width: '16px'
            },

            '& h3': {
                fontSize: '14px',
                lineHeight: '18px',
                fontWeight: 600,
                margin: 'unset'
            }
        }
    },
    priceBlock: {
        border: '1px solid #C5C5D1',
        borderRadius: '8px',
        padding: '8px',

        '& h1': {
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: 400,
            margin: 'unset'
        },

        '& h2': {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 400,
            margin: 'unset'
        }
    },
    nextButtonStyles: {
        ...nextButton.nextButton,
        margin: '24px 0 12px',

        '&:active': {
            ...nextButton.nextButtonHovered
        }
    },
    nextButtonClicked: {
        ...nextButton.nextButtonDisabled,
        background: '#CA5010'
    },
    '@media (min-width: 768px)': {
        programWrapper: {
            padding: '24px',
            marginTop: '32px',
        },
        programTitle: {
            margin: 'unset',
            fontSize: '34px',
            lineHeight: '44px',
            textAlign: 'left',
        },
        medicationWrapper: {
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'row-reverse',
            gap: '40px',
            padding: '24px 0 32px',

            '& img': {
                width: '272px',
                height: '194px'
            }
        },
        programItemBlock: {
            gap: '24px',
            marginTop: 'unset',
        },
        programItem: {

            '& h3': {
                fontSize: '20px',
                lineHeight: '26px'
            }
        },
        medicationOptions: {
            margin: '32px 0',

            '& $programItem': {
                gap: '12px',
                alignItems: 'center',
                textAlign: 'left',

                '& img': {
                    width: '28px'
                },

                '& h3': {
                    fontSize: '20px',
                    lineHeight: '24px',
                    fontWeight: 600,
                    margin: 'unset'
                },

                '&:last-child': {
                    marginBottom: 0,
                },
            }
        },
        priceBlock: {
            padding: '12px',
            marginBottom: '40px',
            '& h1': {
                fontSize: '30px',
                lineHeight: '32px',
                marginBottom: '8px',
            },
            '& h2': {
                fontSize: '16px',
                lineHeight: '20px',
                margin: 'unset'
            }
        },
        nextButtonStyles: {
            margin: 'unset',
            maxWidth: '375px'
        }
    }
}));
