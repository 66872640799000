import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';

import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';
import { LinksKeys } from 'constants/links';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';
import { CheckPointsList } from 'components/check-points-list';

import { useStyles } from './styles';

export const Consent = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const {
        title,
        subtitle,
        listWrapper,
        listItem,
        listItemText,
        link
    } = useStyles();

    const itemsTextKeys = [
                'consent-list-item-sole-user', 'consent-list-item-truthful-answers',
                'consent-list-item-one-medication', 'consent-list-item-terms-privacy-policy'
    ];

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const termsEvent = () => pageConfiguration.event('RxWeightTermsClicked');
    const privacyEvent = () => pageConfiguration.event('RxWeightPrivacyPolicyClicked');

    const termsLink =
        <a  className={link}
            href={LinksKeys.TermsAndCondition}
            target='_blank'
            rel='noreferrer nofollow'
            onClick={termsEvent}>
        </a>;

    const privacyLink =
        <a  className={link}
            href={LinksKeys.PrivacyPolicy}
            target='_blank'
            rel='noreferrer nofollow'
            onClick={privacyEvent}>
        </a>;

    const mw_plan_loader_screen = useFeatureIsOn(ExperimentsKeys.mw_plan_loader_screen);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mw_plan_loader_screen, mw_plan_loader_screen ? '1' : '0');
    }, [mw_plan_loader_screen]);

    return (
        <>
            <Typography variant={TypographyVariants.h1} className={title}>
                <Trans i18nKey='consent-title' />
            </Typography>

            <Typography variant={TypographyVariants.h2} className={subtitle}>
                <Trans i18nKey='consent-subtitle' />
            </Typography>

            <div className={listWrapper}>
                <CheckPointsList items={itemsTextKeys} listItemClassName={listItem} listItemTextClassName={listItemText} listItemTextComponents={[
                    termsLink, privacyLink
                ]} />
            </div>

            <NextButton typographyText={t('consent-button-next')} onClick={handleClick} />
        </>
    );
};
