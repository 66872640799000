import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    contentWrapper: {
        maxWidth: '560px',

        '& h2': {
            margin: '0 0 32px'
        },

        '& > div': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            marginBottom: '12px',

            '& > div': {
                display: 'flex',
                alignItems: 'flex-start',
                gap: '24px',

                '& > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '13px',

                    '& img': {
                        width: '54px',
                        height: '54px'
                    },

                    '& p:first-of-type': {
                        fontSize: '16px',
                        color: '#5E626B'
                    },

                    '& p': {
                        fontSize: '14px',
                        lineHeight: 'normal',
                        fontWeight: 400,
                        margin: 'unset',
                        maxWidth: '139px',
                    }
                },
            }
        }
    },
    '@media (min-width: 768px)' : {
        contentWrapper: {

            '& h2': {
                margin: '-24px 0 40px'
            },

            '& > div': {
                gap: '14.27px',
                marginBottom: '60px',

                '& > div > div': {

                    '& img': {
                        width: '76px',
                        height: '76px'
                    },

                    '& p': {
                        maxWidth: '194px',
                    }
                }
            },
        },
    }
});
