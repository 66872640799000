import { isString, uuidv4 } from 'helpers';

import { AnalyticsKeys } from 'constants/analytics';

export interface ZarazServiceInterface {
    event: (name: string, params: any) => void;
}

export class AnalyticsService implements ZarazServiceInterface {

    private userId = localStorage.getItem(AnalyticsKeys.userId) || uuidv4();
    private useLog: boolean = window.location.origin.includes('localhost');

    constructor() {
        localStorage.setItem(AnalyticsKeys.userId, this.userId);
    }

    private logEvent(name: string, data = {}) {
        console.log(
            `%c[FIRED CLIENT EVENT] ${name}`,
            "color:orange; font-size: 16px; text-transform: capitalize;"
        );
        console.table({
            "EVENT NAME": name,
            "EVENT DATA": Object.keys(data).length ? JSON.stringify(data) : '-'
        });
        console.log(" ------------------------------ ");
    }

    private trackZaraz(name: string, data: {} | [] | string = '') {
        if (isString(data)) {
            data = { value: data };
        }

        if (Array.isArray(data)) {
            data = { value: data };
        }

        const params = Object.assign({
            'event_release_version': process.env.REACT_APP_RELEASE_VERSION
        }, data);

        try {
            if (window.zaraz) {
                window.zaraz.track(name, params);
            }
        } catch (e: any) {
            console.error(`Failed to send Zaraz event ${name}`, e);
        }
    }

    public event(name: string, params: {} | string = '') {
        if (this.useLog) {
            return;
        }

        this.logEvent(name, params);

        try {
            this.trackZaraz(name, params);
        } catch (e) {
            console.log(`zaraz`, e);
        }
    }
}
