import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '44px',

        '& > img': {
            width: '100%',
            maxWidth: '226px',
        },

        '& > div': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '26px',
        },

        '& > div > div': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            gap: '10px',

            '& img': {
                width: '22px',
                height: '12px'
            },

            '& p': {
                fontSize: '14px',
                lineHeight: 'normal',
                fontWeight: 400,
                textAlign: 'left',
                margin: 'unset'
            }
        },
    },
    '@media (min-width: 768px)' : {
        contentWrapper: {
            marginBottom: '60px',

            '& > img': {
                maxWidth: '331px',
            },

            '& > div': {
                gap: '50px',
                marginTop: '60px',
            },

            '& > div > div': {
                gap: '20px',

                '& img': {
                    width: '22px',
                    height: '12px'
                },

                '& p': {
                    fontSize: '16px',
                    lineHeight: '24px',
                }
            },
        }
    },
    '@media (max-width: 428px)' : {
        contentWrapper: {

            '& > div': {
                gap: '30px',
                minWidth: '40%',
            },
        }
    },
    '@media (max-width: 390px)' : {
        contentWrapper: {

            '& > div': {
                gap: '26px',
                minWidth: '40%',
            },
        }
    },
    '@media (max-width: 375px)' : {
        contentWrapper: {

            '& > div': {
                gap: '26px',
                minWidth: '45%',
            },

            '& > img': {
                maxWidth: '180px',
            },

            '& > div > div': {

                '& p': {
                    fontSize: '12px',
                }
            },
        }
    },
    '@media (max-width: 360px)' : {
        contentWrapper: {

            '& > div': {
                gap: '16px',
            },
        }
    }
});
