export const ExperimentsKeys = {
    mw_plan_loader_screen: 'mw_plan_loader_screen',
    mw_medical_director_changes_v2: 'mw_medical_director_changes_v2',
    mw_subscription_price_variations: 'mw_subscription_price_variations',
    mw_email_screen_removal: 'mw_email_screen_removal',
    mw_how_sema_works: 'mw_how_sema_works',
    mw_first_screen: 'mw_first_screen',
    mw_progress_bar_on_checkout_screen: 'mw_progress_bar_on_checkout_screen',
    mw_trial_picker_screen: 'mw_trial_picker_screen',
    mw_pre_paywall_on_paywall: 'mw_pre_paywall_on_paywall',
    mw_medication_upsell: 'mw_medication_upsell',
};
