import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { useBackgroundImageService } from 'hooks/use-background-image-service.hook';

import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';

import { useStyles } from './styles';

const IMAGE_PATHS = ['svg/medication-plan/order-declined-block/warning.svg'];

interface OrderDeclinedBlockProps {
    title: string;
    description: string;
}

export const OrderDeclinedBlock: FC<OrderDeclinedBlockProps> = ({ title, description }) => {
    const { wrapper} = useStyles();

    const pageImages = useBackgroundImageService(IMAGE_PATHS);

    return (
        <div className={wrapper}>
            <img src={pageImages[0]} alt='Warning' />
            <div>
                <Typography variant={TypographyVariants.p}>
                    <Trans i18nKey={title} />
                </Typography>
                <Typography variant={TypographyVariants.p}>
                    <Trans i18nKey={description} />
                </Typography>
            </div>
        </div>
    );
};
