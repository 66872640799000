import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { TypographyVariants } from 'constants/typography-variants';
import { ExperimentsKeys } from 'constants/experiments';

import { Typography } from 'components/typography';
import { NextButton } from 'components/next-button';

import { ContentV1 } from 'pages/how-sema-works/content-v1';
import { ContentV2 } from 'pages/how-sema-works/content-v2';

import { useStyles } from './styles';

export const HowSemaWorks = () => {
    const { pageWrapper, orange } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { t } = useTranslation();

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const mw_how_sema_works = localStorage.getItem(ExperimentsKeys.mw_how_sema_works);

    return (
        <div className={pageWrapper}>
            <Typography variant={TypographyVariants.h1}>
                <Trans i18nKey='how-sema-works-title'
                       components={[<span className={mw_how_sema_works === '2' ? orange : ''}></span>]} />
            </Typography>

            {mw_how_sema_works === '1' && <ContentV1 />}
            {mw_how_sema_works === '2' && <ContentV2 />}

            <NextButton
                bottomMargin
                onClick={handleClick}
                typographyText={t('how-sema-works-button-next')}
            />
        </div>
    );
};
